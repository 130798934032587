import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

firebase.initializeApp({
  apiKey: 'AIzaSyCTfFe61f-orwbpAm1RY8jISjTJP72CqP4',
  authDomain: 'golly-gorillas.firebaseapp.com',
  databaseURL: 'https://golly-gorillas.firebaseio.com',
  projectId: 'golly-gorillas',
  storageBucket: 'golly-gorillas.appspot.com',
  messagingSenderId: '700540175610',
  appId: '1:700540175610:web:85304778f4c8314f153543'
})

export const db = firebase.firestore()
export const auth = firebase.auth()
