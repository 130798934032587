import * as React from 'react'
import { Button, Modal } from '../ui'
import * as ListsApi from '../api/lists'

interface Props {
  open: boolean,
  onClose: () => void,
  listId: string,
  onDelete: () => void
}

export function DeleteListConfirmationModal({ open, onClose, listId, onDelete }: Props) {
  const [deleting, setDeleting] = React.useState(false)

  const deleteList = React.useCallback(async () => {
    setDeleting(true)
    await ListsApi.remove(listId)
    setDeleting(false)
    onDelete()
  }, [listId, setDeleting, onDelete])

  const footerButtons = [
    <Button key="no" type="ghost" title="No, Cancel"/>,
    <Button
      key="yes"
      title="Yes, Delete It"
      color="red"
      loading={deleting}
      onClick={deleteList}
    />
  ]

  return (
    <Modal {...{ open, onClose, footerButtons }} title="Delete List?">
      <p>Are you sure you want to delete this list?</p>
    </Modal>
  )
}