import moment from 'moment'
import pick from 'lodash/pick'
import { db } from '../config/firebase'

const listsCollection = db.collection('/lists')

function decodeList(data: any): List {
  return {
    ...pick(data, ['id', 'name', 'description', 'items', 'lastModifiedBy']),
    createdBy: data.createdBy as UserType,
    createdAt: moment(data.createdAt),
    lastModified: moment(data.lastModified)
  }
}

function encodeList(list: List): any {
  return {
    ...pick(list, ['id', 'name', 'description', 'items', 'createdBy', 'lastModifiedBy']),
    createdAt: list.createdAt.valueOf(),
    lastModified: list.lastModified.valueOf()
  }
}

export function subscribeAll(
  onListsChanged: (lists: List[]) => void,
  collection = listsCollection
) {
  return collection.onSnapshot(snapshot => {
    const lists: List[] = snapshot.docs
      .map(snapshot => {
        const list = decodeList(snapshot.data())
        list.id = snapshot.id
        return list
      })
    onListsChanged(lists)
  })
}

export function subscribeOne(
  dbId: string,
  onChange: (list: List | null) => void,
  collection = listsCollection
) {
  return collection.doc(dbId).onSnapshot(snapshot => {
    const data = snapshot.data()
    if (!data) return onChange(null)

    const list = decodeList(data)
    list.id = snapshot.id
    onChange(list)
  })
}

export async function update(
  list: List,
  user: UserType,
  collection = listsCollection
) {
  const patch = {
    ...list,
    lastModifiedBy: user,
    lastModified: Date.now()
  }
  collection.doc(list.id)
    .update(encodeList(patch))
}

export async function create(
  list: List,
  collection = listsCollection
) {
  const data = encodeList(list)
  const result = await collection.add(data)
  return result.id
}

export async function remove(
  listId: string,
  collection = listsCollection
) {
  await collection.doc(listId).delete()
}
