import * as React from 'react'
import styled from 'styled-components'
import { FaQuestionCircle, FaExclamationCircle, FaCheckCircle } from 'react-icons/fa'
import parseColor from 'color'
import { ColorOption } from '../util/color'

type BannerType = 'danger' | 'info' | 'success'

interface Props {
  title: JSX.Element | string,
  children?: JSX.Element[] | string
  type?: BannerType,
}

export function MessageBanner({ title, type = 'info', children }: Props) {
  const icon = type === 'info'
    ? <FaQuestionCircle/>
    : type === 'success'
      ? <FaCheckCircle/>
      : <FaExclamationCircle/>
  const color: ColorOption = type === 'info'
    ? 'blue'
    : type === 'success'
      ? 'green'
      : 'red'

  return (
    <Banner color={color}>
      <Title>{icon}&nbsp;{title}</Title>
      <Message>{children}</Message>
    </Banner>
  )
}

interface BannerProps {
  color: ColorOption
}

const Banner = styled.div<BannerProps>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: ${({ color }) => parseColor(color).lighten(0.8).string()};
  border: 1px solid ${({ color }) => parseColor(color).darken(0.2).string()};
  color: ${({ color }) => parseColor(color).darken(0.2).string()};
  border-radius: 12px;
  
  a {
    color: ${({ color }) => parseColor(color).darken(0.2).string()} !important;
    font-weight: bold !important;
    text-decoration: underline !important;
  }
`

const Title = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
`

const Message = styled.div`
  font-size: 14px;
`