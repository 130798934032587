import * as React from 'react'
import styled from 'styled-components'

type HTMLTextAreaProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>

interface Props extends Omit<HTMLTextAreaProps, 'size' | 'ref'> {
  label?: string,
  size?: 'small' | 'medium' | 'large',
  hasError?: boolean,
  rows?: number,
  autofocus?: boolean,
  style?: React.CSSProperties,
}

export function TextArea({ size = 'medium', hasError = false, label, style = {}, autofocus, ...props }: Props) {
  const ref = React.useRef<HTMLTextAreaElement | null>(null)

  React.useEffect(() => {
    if (autofocus && ref.current) {
      ref.current.focus()
    }
  }, []) // Ignore empty deps array; we only want this to happen once on mount

  const fontSize = size === 'large' ? 36 : size === 'medium' ? 16 : 14
  return (
    <div style={style}>
      {label && <Label>{label}</Label>}
      <StyledTextArea {...props} {...{ ref }} hasError={hasError} style={{ fontSize }}/>
    </div>
  )
}

interface TextAreaProps {
  hasError: boolean
}

const StyledTextArea = styled.textarea<TextAreaProps>`
  resize: none;
  vertical-align: middle;
  outline: none;
  font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
  padding: 10px;
  transition: 300ms all ease-in-out;
  border: 1px solid ${({ hasError }) => hasError ? '#9c3319' : 'lightgrey'};
  border-radius: 12px;
  color: #333;
  box-sizing: border-box;
  
  :active, :focus {
    border-color: ${({ hasError }) => hasError ? '#9c3319' : '#269E19'}
  }
`

const Label = styled.span`
  font-size: 13px;
  font-weight: bold;
`

