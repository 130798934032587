import * as React from 'react'
import styled from 'styled-components'
import { FaImage } from 'react-icons/fa'
import { useMediaQuery } from 'react-responsive'
import { SelectCoverPhotoModal } from './SelectCoverPhotoModal'

interface Props {
  imageUrl?: string,
  onSelectImageUrl: (imageUrl: string) => void
}

export function CoverPhotoPreview({ imageUrl, onSelectImageUrl }: Props) {
  const [showModal, setShowModal] = React.useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  const changeOrSelectPrompt = (
    <>
      <div style={{ fontSize: 24 }}>
        <FaImage/>
      </div>
      <span>{!!imageUrl ? 'Change' : 'Select'} Cover Photo</span>
    </>
  )

  return (
    <>
      <CoverPhotoContainer>
        <CoverPhotoButton dashed={!imageUrl} onClick={() => setShowModal(true)}>
          {imageUrl
            ? <>
              <img src={imageUrl}/>
              <CoverPhotoChange>{changeOrSelectPrompt}</CoverPhotoChange>
            </>
            : changeOrSelectPrompt
          }
        </CoverPhotoButton>
        {isMobile && imageUrl && <span>Click to change</span>}
      </CoverPhotoContainer>
      <SelectCoverPhotoModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onSelectImage={imageUrl => {
          setShowModal(false)
          onSelectImageUrl(imageUrl)
        }}
      />
    </>
  )
}

const CoverPhotoChange = styled.div`
  background: rgba(0,0,0,0.5);
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
`

const CoverPhotoButton = styled.div<{ dashed: boolean }>`
  width: 100%;
  height: 200px;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: darkgray;
  border: ${({ dashed }) => dashed ? '2px dashed lightgray' : 'none'};
  position: relative;
  cursor: pointer;
  transition: all 300ms ease-in-out;
  
  img {
    object-fit: cover;
    width: 100%;
  }
  
  &:hover {
    ${CoverPhotoChange} {
      opacity: 1;
    }
    
    color: darkgray;
    border-color: ${({ dashed }) => dashed ? 'darkgray' : 'none'};
  }
`

const CoverPhotoContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
  /* Smartphones (portrait) ----------- */
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`
