import * as React from 'react'
import styled from 'styled-components'
import { FaGripVertical, FaTimes } from 'react-icons/fa'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

interface Props<T> {
  id: string,
  items: T[],
  getKey: (item: T, index: number) => string,
  onChange: (newItems: T[]) => void,
  onAddItem: (item: string) => void,
  renderItem: (item: T, index: number) => JSX.Element,
  addItemPlaceholder?: string,
  ref?: React.Ref<DraggableList<T>>,
  style?: React.CSSProperties
}

interface State {
  newItemValue: string
}

export class DraggableList<T> extends React.PureComponent<Props<T>, State> {
  newItemInputRef = React.createRef<HTMLInputElement>()

  state: State = { newItemValue: '' }

  focusNewItemInput() {
    this.newItemInputRef.current && this.newItemInputRef.current.focus()
  }

  onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) return

    const sourceIndex = source.index
    const destIndex = destination.index
    const items = reorderList(this.props.items, sourceIndex, destIndex)
    this.props.onChange(items)
  }

  deleteItem(index: number) {
    const items = [...this.props.items]
    items.splice(index, 1)
    this.props.onChange(items)
  }

  render() {
    const { id, items, getKey, renderItem, onAddItem, addItemPlaceholder, style } = this.props

    return (
      <div style={style}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId={id} direction="vertical">
            {({ innerRef, droppableProps, placeholder }) =>
              <List ref={innerRef} {...droppableProps}>
                {items.map((item, idx) => {
                  const key = getKey(item, idx)

                  return (
                    <Draggable key={key} draggableId={key} index={idx}>
                      {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) =>
                        <ListItem ref={innerRef} {...draggableProps} dragging={isDragging}>
                          <GrabHandle {...dragHandleProps}>
                            <FaGripVertical/>
                          </GrabHandle>
                          {renderItem(item, idx)}
                          <ListItemDelete onClick={() => this.deleteItem(idx)}>
                            <FaTimes/>
                          </ListItemDelete>
                        </ListItem>
                      }
                    </Draggable>
                  )
                })}
                {placeholder}
              </List>
            }
          </Droppable>
        </DragDropContext>
        {!!items.length && <Divider/>}
        <NewItemInput
          ref={this.newItemInputRef}
          placeholder={addItemPlaceholder}
          value={this.state.newItemValue}
          onChange={e => this.setState({ newItemValue: e.target.value })}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onAddItem(this.state.newItemValue)
              this.setState({ newItemValue: '' })
            }
          }}
        />
      </div>
    )
  }
}

export function reorderList<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const GrabHandle = styled.div`
  width: 16px;
  height: 16px;
  color: #aeaeae;
  margin-right: 6px;
  opacity: 0;
  transition: 100ms all ease-in-out;
`

const ListItemDelete = styled.div`
  cursor: pointer;
  opacity: 0;
  transition: 100ms all ease-in-out;
  color: #aeaeae;

  &:hover {
    color: darkgray;
  }
`

export const ListItemInput = styled.input.attrs(() => ({ type: 'text' }))`
  outline: none;
  font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background: transparent;
  border: 1px solid transparent;
  font-size: 14px;
  width: 100%;
  padding: 6px;
  margin: 0 6px;
  border-radius: 6px;
  transition: 100ms all ease-in-out;
  box-sizing: border-box;
  
  &:hover {
    border-color: lightgray;
  }

  &:active, &:focus {
    border-color: darkgray;
    
    + ${ListItemDelete} {
      opacity: 1;
    }
  }
`

export const NewItemInput = styled(ListItemInput)`
  border-color: transparent;
  margin-left: 6px;        // <-
  width: calc(100% - 6px); // <- These lines ensure this input lines up with the offset ones above it
  transition: 300ms all ease-in-out;

  &:hover, &:active, &:focus {
    border-color: darkgray;
  }
`

const ListItem = styled.li<{ dragging: boolean }>`
  display: flex;
  align-items: center;
  margin-left: -16px;
  box-shadow: ${({ dragging }) => dragging ? '0 4px 10px rgba(0,0,0,0.25)' : 'none'};
  background: ${({ dragging }) => dragging ? 'white' : 'inherit'};
  border-radius: 6px;

  &:hover {
    ${GrabHandle}, ${ListItemDelete} {
      opacity: 1;
    }
  }

  ${({ dragging }) => dragging && `${GrabHandle} {
    visibility: visible;
  }`};
`

const Divider = styled.hr`
  border: none;
  border-top: 1px solid lightgray;
  margin-left: 6px;
`
