import Linkify from 'react-linkify'
import * as React from 'react'

interface Props {
  text: string
}

const componentDecorator = (href: string) => {
  const text = href.length === 40 ? href : href.substring(0, 40) + '...'
  return <a href={href} target="_blank" rel="noopener noreferrer">{text}</a>
}

export function TextWithLinks({ text }: Props) {
  const body = text
    .split(/(\r\n|\r|\n)/g)
    .map(line => line.replace(/(\r\n|\r|\n)/g, ''))
    .map(line => <div key={line}>{line}</div>)

  return (
    <Linkify {...{ componentDecorator }}>
      {body}
    </Linkify>
  )
}