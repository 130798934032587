import * as React from 'react'
import styled from 'styled-components'

interface ActionButton {
  title: JSX.Element | string,
  onClick: () => void,
  icon?: React.ComponentType<any>
}

interface Props {
  title: string,
  level?: number,
  action?: ActionButton | null
}

export function SectionHeader({ title, level = 1, action }: Props) {
  let button = null
  if (action) {
    const { icon: Icon, title } = action
    button = (
      <LinkButton onClick={action.onClick}>
        {title} {Icon && <Icon style={{ marginLeft: 6 }}/>}
      </LinkButton>
    )
  }

  return (
    <Container>
      {React.createElement(`h${level}`, {}, title)}{button}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const LinkButton = styled.a.attrs(() => ({ role: 'button' }))`
  margin-left: 24px;
  display: flex;
  align-items: center;
`