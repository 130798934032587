import moment from 'moment'
import pick from 'lodash/pick'
import { db } from '../config/firebase'

const dateFormat = 'YYYY-MM-DD'

const hangoutsCollection = db.collection('/hangouts')

function decodeHangout(data: any): HangoutDetails {
  return {
    ...pick(data, ['id', 'location', 'activities', 'notes', 'imageUrl', 'title', 'archived']),
    startDate: moment(data.startDate, dateFormat),
    endDate: moment(data.endDate, dateFormat),
    rsvps: data.rsvps || []
  }
}

function encodeHangout(hangout: HangoutDetails): any {
  return {
    ...pick(hangout, ['id', 'location', 'activities', 'notes', 'imageUrl', 'title', 'archived']),
    startDate: hangout.startDate.format(dateFormat),
    endDate: hangout.endDate.format(dateFormat),
    rsvps: hangout.rsvps || [],
  }
}

export function subscribeAll(
  onHangoutsChanged: (hangouts: HangoutDetails[]) => void,
  collection = hangoutsCollection
) {
  return collection.onSnapshot(snapshot => {
    const hangouts: HangoutDetails[] = snapshot.docs
      .map(snapshot => {
        const hangout = decodeHangout(snapshot.data())
        hangout.id = snapshot.id
        return hangout
      })
      .filter(({ archived }) => !archived)
    onHangoutsChanged(hangouts)
  })
}

export function subscribeOne(
  dbId: string,
  onChange: (hangout: HangoutDetails | null) => void,
  collection = hangoutsCollection
) {
  return collection.doc(dbId).onSnapshot(snapshot => {
    const data = snapshot.data()
    if (!data) return onChange(null)

    const hangout = decodeHangout(data)
    if (hangout.archived) return onChange(null)

    hangout.id = snapshot.id
    onChange(hangout)
  })
}

export async function update(
  hangout: HangoutDetails,
  collection = hangoutsCollection
) {
  collection.doc(hangout.id)
    .update(encodeHangout(hangout))
}

export async function sendRsvp(coming: boolean, hangout: HangoutDetails, user: UserType) {
  const newHangout = { ...hangout }
  if (coming) {
    newHangout.rsvps.push(user)
  } else {
    newHangout.rsvps = hangout.rsvps.filter(rsvp => rsvp !== user)
  }

  await update(newHangout)
}

export async function create(
  hangout: HangoutDetails,
  collection = hangoutsCollection
) {
  const data = encodeHangout(hangout)
  const result = await collection.add(data)
  return result.id
}

export async function archive(
  hangout: HangoutDetails,
  collection = hangoutsCollection
) {
  await collection.doc(hangout.id)
    .update(encodeHangout({ ...hangout, archived: true }))
}
