import * as React from 'react'
import styled from 'styled-components'
import noop from 'lodash/noop'
import parseColor from 'color'
import { ColorOption, getColor } from '../util/color'
import { LoadingSpinner } from './Spinner'

interface ButtonProps {
  disabled?: boolean,
  size: 'small' | 'medium' | 'large',
  color: string,
  noHover?: boolean
}

const StyledButton = styled.a<ButtonProps>`
  display: flex;
  padding: ${({ size }) => size === 'large' ? '12px 48px' : size === 'medium' ? '12px' : '6px'};
  font-size: ${({ size }) => size === 'small' ? 12 : 14}px;
  align-items: center;
  justify-content: center;
  background-color: ${({ disabled, color }) => disabled ? 'lightgrey' : color};
  color: white;
  border-radius: 24px;
  box-sizing: border-box;
  text-transform: none;  
  user-select: none;
  ${({ disabled }) => disabled ? 'cursor: not-allowed' : ''};
  
  &:hover {
    ${({ noHover, disabled, color }) => !noHover && !disabled && `background-color: ${parseColor(color).darken(0.11).string()}`};
    color: white;
    ${({ noHover, disabled }) => !noHover && !disabled && 'transform: scale(1.0125)'};
  }
`

const GhostButton = styled(StyledButton)`
  border: 1px solid;
  background-color: transparent;
  border-color: ${({ disabled, color }) => disabled ? 'lightgrey' : color};
  color: ${({ disabled, color }) => disabled ? 'lightgrey' : color};
  
  &:hover {
    color: ${({ color }) => parseColor(color).darken(0.11).string()};
    border-color: ${({ color }) => parseColor(color).darken(0.11).string()};
    background-color: transparent;
  }
`

interface Props {
  title: string | JSX.Element,
  onClick?: (e: React.MouseEvent) => void,
  disabled?: boolean,
  size?: 'small' | 'medium' | 'large',
  type?: 'filled' | 'ghost',
  color?: ColorOption,
  noHover?: boolean,
  loading?: boolean,
  style?: React.CSSProperties
}

export function Button({ title, onClick, disabled, size, type, color, noHover, loading, ...rest }: Props) {
  const bgColor = getColor(color || 'green')

  const props = {
    role: 'button',
    disabled,
    onClick,
    size: size || 'medium',
    color: bgColor,
    noHover: noHover || false
  }

  let btnTitle = <>{title}</>
  if (loading) {
    props.noHover = true
    props.onClick = noop

    const spinnerColor = type === 'ghost' ? bgColor : 'white'
    btnTitle = <>
      {title} <LoadingSpinner style={{ marginLeft: 6, color: spinnerColor }}/>
    </>
  }

  switch (type) {
    case 'ghost':
      return <GhostButton {...props} {...rest}>{btnTitle}</GhostButton>
    case 'filled':
    default:
      return <StyledButton {...props} {...rest}>{btnTitle}</StyledButton>
  }
}
