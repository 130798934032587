import moment from 'moment'
import * as React from 'react'
import ContentEditable from 'react-contenteditable'
import { Autosuggest, TextArea, DateRangeSelectorInput, UnderlinedLabel } from '../ui'
import { locations } from '../pages/CreatePage/HangoutDetailsForm'
import { TextWithLinks } from './TextWithLinks'

interface EditableDateRangeProps {
  startDate: moment.Moment,
  endDate: moment.Moment,
  onSave: (starDate: moment.Moment, endDate: moment.Moment) => void
}

export function EditableDateRange({ startDate, endDate, onSave }: EditableDateRangeProps) {
  const [editing, setEditing] = React.useState(false)

  if (!editing) {
    return (
      <h1 style={{ cursor: 'pointer', margin: 0 }} onClick={() => setEditing(true)}>
        <span>{startDate.format('MMM D')}</span>
        &nbsp;-&nbsp;
        <span>{endDate.format('MMM D')}</span>
      </h1>
    )
  }

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <DateRangeSelectorInput
        style={{ width: 300 }}
        autofocus
        {...{ startDate, endDate }}
        onChange={(start, end) => {
          onSave(start, end)
          setEditing(false)
        }}
      />
    </div>
  )
}

interface EditableAutoSuggestFieldProps {
  value: string,
  onSave: (value: string) => void,
  placeholder?: string
}

export function EditableAutoSuggestField({ value, onSave, placeholder }: EditableAutoSuggestFieldProps) {
  const [editing, setEditing] = React.useState(false)

  if (!editing) {
    const style: React.CSSProperties = { cursor: 'pointer' }
    if (!value) {
      style.color = 'gray'
    }

    return (
      <div style={style} onClick={() => setEditing(true)}>
        <div>{value || placeholder}</div>
      </div>
    )
  }

  return (
    <Autosuggest
      style={{ flex: 1 }}
      autofocus
      size="small"
      placeholder="Select a location..."
      value={value}
      options={locations}
      onSelect={location => {
        onSave(location)
        setEditing(false)
      }}
      onClose={() => setEditing(false)}
    />
  )
}

interface EditableTextAreaProps {
  placeholder: string,
  value: string,
  onSave: (value: string) => void,
  label?: string,
  style?: React.CSSProperties
}

export function EditableTextArea({ label, placeholder, value, onSave, style }: EditableTextAreaProps) {
  const [editing, setEditing] = React.useState(false)
  const [contents, setContents] = React.useState(value)

  if (!editing) {
    return (
      <div style={{ cursor: 'pointer', ...style }} onClick={() => setEditing(true)}>
        {label && <UnderlinedLabel>{label}</UnderlinedLabel>}
        {value
          ? <TextWithLinks text={value}/>
          : <span style={{ fontSize: 14, color: 'darkgrey' }}>{placeholder}</span>
        }
      </div>
    )
  }

  return (
    <TextArea
      autofocus
      style={{ flex: 1 }}
      {...{ label, placeholder }}
      value={contents}
      onBlur={() => {
        onSave(contents)
        setEditing(false)
      }}
      rows={4}
      size="small"
      onChange={e => setContents(e.target.value)}
    />
  )
}

interface EditableTextFieldProps {
  placeholder: string,
  value: string,
  onSave: (value: string) => void,
  style?: React.CSSProperties,
  tagName?: string
}

export function EditableTextField({ placeholder, value = '', onSave, style, tagName = 'h1' }: EditableTextFieldProps) {
  const ref = React.useRef<HTMLHeadingElement>()
  const [contents, setContents] = React.useState(value)

  React.useEffect(() => {
    setContents(value)
  }, [value, setContents])

  const contentEditable = (
    // @ts-ignore (the types for this are messed up for some reason)
    <ContentEditable
      innerRef={ref as React.MutableRefObject<HTMLHeadingElement>}
      style={{ outline: 'none' }}
      html={contents}
      onChange={(e: any) => setContents(e.target.value)}
      tagName={tagName}
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          ref.current!.blur()
        }
      }}
    />
  )

  const placeholderContents = React.createElement(
    tagName,
    {
      style: { color: '#aeaeae', position: 'absolute', top: 0 },
      onClick: () => ref.current && ref.current.focus()
    },
    placeholder
  )

  return (
    <div
      style={{ position: 'relative', cursor: 'pointer', ...style }}
      onBlur={() => onSave(contents)}
    >
      {contentEditable}
      {!contents && placeholderContents}
    </div>
  )
}
