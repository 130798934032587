import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import { FaSpinner } from 'react-icons/fa'
import { ColorOption, getColor } from '../util/color'

interface Props {
  size?: 'small' | 'large'
  color?: ColorOption
  style?: React.CSSProperties
}

export function LoadingSpinner({ size = 'small', color = 'black', style = {} }: Props) {
  return (
    <div style={{ display: 'flex' }}>
      <Container {...{ size, style, color }}>
        <FaSpinner/>
      </Container>
    </div>
  )
}

const rotate = keyframes`
  from { transform: rotate(0deg) }

  to { transform: rotate(360deg) }
`

const Container = styled.div<{ size: 'small' | 'large', color: ColorOption }>`
  font-size: ${({ size }) => size === 'small' ? 14 : 24}px;
  height: ${({ size }) => size === 'small' ? 14 : 24}px;
  color: ${({ color }) => getColor(color)};
  transform-origin: center;
  animation: ${rotate} 1.5s linear infinite;
`
