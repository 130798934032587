import * as React from 'react'
import styled from 'styled-components'
import { getColorForUser } from '../util/color'
import { Button } from '../ui'

interface Props {
  rsvps: UserType[],
  currentUser?: UserType,
  onClickRsvp?: (coming: boolean) => void
}

export function Rsvps({ rsvps, currentUser, onClickRsvp }: Props) {
  return (
    <Container>
      {rsvps.map(p => {
        const base = { key: p, size: 'small' as 'small', color: getColorForUser(p) }
        if (currentUser && p === currentUser) {
          return <Button
            {...base}
            title={`X ${p}`}
            onClick={e => {
              e.stopPropagation()
              onClickRsvp && onClickRsvp(false)
            }}
          />
        }
        return <Button {...base} noHover title={p}/>
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  
  > *:not(:last-child) {
    margin-right: 6px;
  }
`
