import * as React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import * as UnsplashApi from '../api/unsplash'
import { Button, Input, LoadingSpinner, Modal, TextButton } from '../ui'

interface Props {
  open: boolean,
  onClose: () => void,
  onSelectImage: (imageUrl: string) => void
}

interface State {
  query: string,
  loading: boolean,
  page: number,
  hasNextPage: boolean,
  images: UnsplashPhoto[] | null
}

export class SelectCoverPhotoModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = { query: '', loading: false, page: 1, hasNextPage: true, images: null }
    this.searchImages = debounce(this.searchImages, 1000)
  }

  searchImages = async (query: string, page = 1) => {
    this.setState({ loading: true })
    await new Promise(res => setTimeout(res, 1000))
    const { totalPages, results } = await UnsplashApi.searchPhotos(query, page)
    const hasNextPage = page < totalPages
    this.setState({ loading: false, images: results, page, hasNextPage })
  }

  prevPage = () => this.searchImages(this.state.query, this.state.page - 1)

  nextPage = () => this.searchImages(this.state.query, this.state.page + 1)

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    this.setState({ query })
    this.searchImages(query)
  }

  render() {
    const { open, onClose, onSelectImage } = this.props
    const { query, loading, images, page, hasNextPage } = this.state

    const footerButtons = [
      <Button key="cancel" title="Cancel" type="ghost" onClick={onClose}/>
    ]

    return (
      <Modal {...{ open, onClose, footerButtons }} title="Choose Cover Photo">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Input
            placeholder={'Search for an image, e.g. "Thanksgiving"'}
            value={query}
            onChange={this.onChange}
          />

          <div style={{ display: 'flex', flex: 1, marginTop: 12, minHeight: 200 }}>
            {!loading && !query && (
              <span>Image results will appear here</span>
            )}
            {loading && (
              <>
                <span style={{ marginRight: 6 }}>Loading images...</span>
                <LoadingSpinner size="large" color="green"/>
              </>
            )}
            {!loading && images && !images.length && (
              <span>It looks like there weren't any result for that... try a different search</span>
            )}
            {!loading && images && !!images.length && (
              <div>
                <div style={{ flex: 1, display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 12 }}>
                  {images.map(({ urls }) =>
                    <ImageOption onClick={() => onSelectImage(urls.regular)}>
                      <img src={urls.small}/>
                    </ImageOption>
                  )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 12 }}>
                  <TextButton
                    text="Previous Page"
                    leading={<FaChevronLeft/>}
                    disabled={page === 1}
                    onClick={this.prevPage}
                  />
                  <TextButton
                    style={{ justifyContent: 'flex-end' }}
                    text="Next Page"
                    trailing={<FaChevronRight/>}
                    disabled={!hasNextPage}
                    onClick={this.nextPage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    )
  }
}

const ImageOption = styled.div`
  display: flex;
  border-radius: 6px; 
  height: 200px;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid transparent;
  transition: border-color 300ms ease-in-out;
  
  img {
    object-fit: cover;
    transition: all 300ms ease-in-out;
  }
  
  &:hover {
    border-color: #269E19;
    
    img {
      transform: scale(1.025);
    }
  }
`
