import * as React from 'react'
import { Button, Modal } from '../ui'
import * as HangoutsApi from '../api/hangouts'

interface Props {
  open: boolean,
  onClose: () => void,
  hangout: HangoutDetails,
  onArchive: () => void
}

export function ArchiveHangoutConfirmationModal({ open, onClose, hangout, onArchive }: Props) {
  const [archiving, setArchiving] = React.useState(false)

  const archiveHangout = React.useCallback(async () => {
    setArchiving(true)
    await HangoutsApi.archive(hangout)
    setArchiving(false)
    onArchive()
  }, [hangout, onArchive])

  const footerButtons = [
    <Button key="no" type="ghost" title="No, Cancel"/>,
    <Button
      key="yes"
      title="Yes, Archive It"
      color="red"
      loading={archiving}
      onClick={archiveHangout}
    />
  ]

  return (
    <Modal {...{ open, onClose, footerButtons }} title="Archive Hangout?">
      <p>Are you sure you want to archive this hangout?</p>
    </Modal>
  )
}