import * as React from 'react'
import styled from 'styled-components'
import { FaCheck } from 'react-icons/fa'

interface Props {
  checked: boolean,
  onChange: (checked: boolean) => void
}

export function Checkbox({ checked, onChange }: Props) {
  return (
    <Box checked={checked} onClick={() => onChange(!checked)}>
      <Check>
        <FaCheck/>
      </Check>
    </Box>
  )
}

const Check = styled.div`
  opacity: 0;
  color: white;
  display: flex;
  align-items: center;
  
  svg {
    width: 10px;
    height: 16px;
  }
`

const Box = styled.div<{ checked: boolean }>`
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid;
  border-color: ${({ checked }) => checked ? '#269E19' : 'lightgrey'};
  cursor: pointer;
  transition: 100ms all ease-in-out;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: ${({ checked }) => checked ? '#269E19' : 'transparent'};
  
  &:hover {
    border-color: ${({ checked }) => checked ? '#269E19' : 'darkgrey'};
  }
  
  ${Check} {
    opacity: ${({ checked }) => checked ? 1 : 0};
  }
`
