export type ColorOption = 'black' | 'blue' | 'red' | 'green' | 'lightgray'

export function getColor(color: ColorOption) {
  switch (color) {
    case 'black':
      return '#333'
    case 'blue':
      return '#1a5b9c'
    case 'red':
      return '#9c3319'
    case 'green':
      return '#269E19'
    case 'lightgray':
    default:
      return '#aaa'
  }
}

export function getColorForUser(user: UserType): ColorOption {
  switch (user) {
    case 'Meg':
      return 'black'
    case 'Ken':
      return 'red'
    case 'Kelsey':
      return 'blue'
    case 'Brian':
    default:
      return 'green'
  }
}