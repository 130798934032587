import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import noop from 'lodash/noop'
import isEqual from 'lodash/isEqual'
import { FaEllipsisV, FaTrash } from 'react-icons/fa'
import { Header, Layout, LoadingSpinner, Main, Menu, MessageBanner, NotificationCenter, Section } from '../../ui'
import * as ListsApi from '../../api/lists'
import { getUser } from '../../util/auth'
import { Breadcrumb } from '../../components/Breadcrumb'
import { EditableTextArea, EditableTextField } from '../../components/Editables'
import { DraggableList, ListItemInput } from '../../components/DraggableList'
import { DeleteListConfirmationModal } from '../../components/DeleteListConfirmationModal'

type Props = RouteComponentProps<{ listId?: string }>

interface State {
  user: UserType | null,
  loading: boolean,
  error: 'not-found' | null,
  showDeleteConfirmationModal: boolean,
  list: List | null
}

export default class ListDetailsPage extends React.PureComponent<Props, State> {
  listRef = React.createRef<DraggableList<string>>()
  unsubscribe = noop
  notificationCenter = React.createRef<NotificationCenter>()

  constructor(props: Props) {
    super(props)

    const { listId } = this.props.match.params

    this.state = {
      user: getUser(),
      loading: !!listId,
      error: null,
      showDeleteConfirmationModal: false,
      list: null
    }
  }

  componentDidMount() {
    const { listId } = this.props.match.params
    if (!listId) return
    this.unsubscribe = ListsApi.subscribeOne(listId, list => {
      this.setState({ list, loading: false })
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  showDeleteConfirmationModal = () => {
    this.unsubscribe() // Unsubscribe from list item updates, since it'll be deleted
    this.unsubscribe = noop // Replace it with noop, since it'll be called in componentWillUnmount
    this.setState({ showDeleteConfirmationModal: true })
  }

  createList = async (parts: Partial<List>) => {
    const { user } = this.state
    if (!user) return null

    const list: List = {
      name: '',
      description: '',
      items: [],
      createdAt: Date.now(),
      createdBy: user,
      lastModified: Date.now(),
      lastModifiedBy: user,
      ...parts
    }
    const listId = await ListsApi.create(list)
    this.props.history.push(`/lists/${listId}`)
    this.unsubscribe = ListsApi.subscribeOne(listId, list => {
      this.setState({ list })
    })
  }

  addListItem = async (text: string) => {
    const { user, list } = this.state
    if (!list) {
      this.setState({ list: { items: [text] } as List })
      await this.createList({ items: [text] })
    } else {
      const items = [...list.items, text]
      await ListsApi.update({ ...list, items }, user!)
    }
  }

  modifyListItem = async (text: string, index: number) => {
    const { user, list } = this.state
    if (!list) return

    if (list.items[index] === text) return

    const items = [...(list.items || [])]
    if (!text) {
      items.splice(index, 1)
    } else {
      items[index] = text
    }
    await ListsApi.update({ ...list, items }, user!)
  }

  onChangeListItems = async (items: string[]) => {
    const { user, list } = this.state
    if (!list) return

    if (isEqual(list.items, items)) return

    this.setState({ list: { ...list, items } })
    await ListsApi.update({ ...list, items }, user!)
  }

  updateName = async (name: string) => {
    const { user, list } = this.state

    if (list) {
      if (list.name === name) return

      await ListsApi.update({ ...list, name }, user!)
    } else {
      await this.createList({ name })
    }
  }

  updateDescription = async (description: string) => {
    const { user, list } = this.state
    if (!list) return await this.createList({ description })

    if (list.description === description) return
    await ListsApi.update({ ...list, description }, user!)
  }

  renderScreen() {
    const { list } = this.state

    return <>
      <EditableTextArea
        label="Description"
        style={{ marginTop: 12 }}
        placeholder="What's this list for? e.g. Keeping track of movies we should watch, or activities we should do. That kind of stuff."
        value={list ? list.description || '' : ''}
        onSave={async description => this.updateDescription(description)}
      />

      <Section>
        <h2>Items</h2>
        <DraggableList
          ref={this.listRef}
          id="list-items"
          items={list ? list.items : []}
          onChange={this.onChangeListItems}
          onAddItem={this.addListItem}
          addItemPlaceholder="Add new list item!"
          getKey={item => item}
          renderItem={(item, idx) => (
            <ListItemInput
              placeholder="Enter a list item"
              defaultValue={item}
              onBlur={e => this.modifyListItem(e.currentTarget.value, idx)}
              onKeyDown={async e => {
                if (e.key === 'Enter') {
                  this.listRef.current && this.listRef.current.focusNewItemInput()
                  await this.modifyListItem(e.currentTarget.value, idx)
                }
              }}
            />
          )}
        />
      </Section>
    </>
  }

  render() {
    const { user, loading, error, list, showDeleteConfirmationModal } = this.state
    if (!user) return <Redirect to="/"/>

    return (
      <Layout>
        {list && list.id && <DeleteListConfirmationModal
          open={showDeleteConfirmationModal}
          onClose={() => this.setState({ showDeleteConfirmationModal: false })}
          listId={list.id}
          onDelete={() => this.props.history.push('/')}
        />}
        <Header user={user}/>
        <NotificationCenter ref={this.notificationCenter}/>
        <Main>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              <Breadcrumb destination={{ to: 'home' }}/>
            </div>
            {list && (
              <Menu
                trigger={<FaEllipsisV/>}
                options={[
                  {
                    title: 'Delete List',
                    icon: <FaTrash/>,
                    onClick: this.showDeleteConfirmationModal
                  }
                ]}
              />
            )}
          </div>
          {!loading && !error && (
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
              <EditableTextField
                style={{ flex: 1 }}
                placeholder="Untitled List"
                value={list ? list.name : ''}
                onSave={this.updateName}
              />
              <span style={{ fontSize: 13, fontStyle: 'italic' }}>
                Click a field to change it. Changes will be saved automatically
              </span>
            </div>
          )}

          {loading && <LoadingSpinner size="large" color="green"/>}
          {!loading && error === 'not-found' && (
            <MessageBanner title="Error" type="danger">
              <p>
                Could not find List. Please try going back and selecting a different one.
              </p>
              <Breadcrumb destination={{ to: 'home' }}/>
            </MessageBanner>
          )}
          {!loading && !error && this.renderScreen()}
        </Main>
      </Layout>
    )
  }
}