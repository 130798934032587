import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const HEADER_HEIGHT = 40

export const Main = styled.main`
  flex: 1;
  max-width: calc(960px - 48px - 48px); // TODO: Make responsive
  padding: calc(${HEADER_HEIGHT}px + 48px) 48px 48px;
  
  /* Smartphones (portrait) ----------- */
  @media screen and (max-width: 800px) {
    max-width: 100%;
    padding: calc(${HEADER_HEIGHT}px + 48px) 24px;
    box-sizing: border-box;
  }
`

export const Section = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  
  /* Smartphones (portrait) ----------- */
  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
`

export const Layout = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100vw;
`

interface HeaderProps {
  user: string | null
}

export function Header({ user }: HeaderProps) {
  return (
    <HeaderWrapper>
      <Container>
        <LogoWrapper>
          <Title to="/">Golly Gorillas</Title>
        </LogoWrapper>
        <Nav>
          {user
            ? <NavItem href="#">Hi, {user}</NavItem>
            : <NavItem href="/who-are-you">Sign In</NavItem>
          }
        </Nav>
      </Container>
    </HeaderWrapper>
  )
}

const Container = styled.div`
  max-width: 1200px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  /* Smartphones (portrait) ----------- */
  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
`

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  height: ${HEADER_HEIGHT}px;
  background-color: #f7faf7;
  border-bottom: 1px solid #654b472b;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const NavItem = styled.a`
  margin: 0 12px;
  font-size: 16px;
  text-transform: uppercase;
`

const LogoWrapper = styled.aside`
  display: flex;
  align-items: center;
`

const Title = styled(Link)`
  margin-left: 6px;
  color: #343434 !important; // Override default anchor color
  font-size: 1.5rem;
`
