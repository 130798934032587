import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { FaCalendarPlus, FaList } from 'react-icons/fa'
import { getUser, storeUser } from '../../util/auth'
import * as HangoutsApi from '../../api/hangouts'
import * as ListsApi from '../../api/lists'
import { SignInModal } from './SignInModal'
import { Header, Layout, Main, Section, LoadingSpinner, Card } from '../../ui'
import { HangoutCard, UpcomingHangoutCard } from '../../components/HangoutCard'
import { SectionHeader } from '../../components/SectionHeader'
import { groupHangouts } from '../../util/hangouts-utils'
import { ListsTable } from './ListsTable'

type Props = RouteComponentProps

interface State {
  user: UserType | null,
  hangoutsLoading: boolean,
  recentHangouts: HangoutDetails[],
  upcomingHangouts: HangoutDetails[],
  listsLoading: boolean,
  lists: List[]
}

export default class HomePage extends React.PureComponent<Props, State> {
  unsubscribes: Array<() => void> = []

  state: State = {
    user: getUser(),
    hangoutsLoading: true,
    recentHangouts: [],
    upcomingHangouts: [],
    listsLoading: true,
    lists: []
  }

  initSubscription() {
    this.unsubscribes.push(
      HangoutsApi.subscribeAll(hangouts => {
        const { recent, upcoming } = groupHangouts(hangouts)
        this.setState({ recentHangouts: recent, upcomingHangouts: upcoming, hangoutsLoading: false })
      }),
      ListsApi.subscribeAll(lists => {
        this.setState({ lists, listsLoading: false })
      }))
  }

  componentDidMount() {
    if (this.state.user) {
      this.initSubscription()
    }
  }

  componentWillUnmount() {
    this.unsubscribes.forEach(fn => fn())
  }

  renderSignInModal() {
    return (
      <SignInModal
        open={!this.state.user}
        onLogin={user => {
          storeUser(user)
          this.setState({ user })
          this.initSubscription()
        }}
      />
    )
  }

  renderUpcomingHangoutsSection(user: UserType) {
    const { upcomingHangouts, hangoutsLoading } = this.state

    return (
      <Section>
        <SectionHeader
          title="Upcoming Hangouts"
          action={{
            title: 'Add',
            icon: FaCalendarPlus,
            onClick: () => this.props.history.push('/hangouts/new')
          }}
        />
        {hangoutsLoading && <LoadingSpinner size="large" color="green"/>}
        {!hangoutsLoading && !upcomingHangouts.length && (
          <span>
            You have no upcoming hangouts! <Link to="/hangouts/new">Plan one</Link> now!
          </span>
        )}
        <HorizontalScroller>
          {upcomingHangouts.map(hangout =>
            <UpcomingHangoutCard
              key={hangout.id}
              currentUser={user}
              onClick={() => this.props.history.push(`/hangouts/${hangout.id}`)}
              hangout={hangout}
              showRsvp={!hangout.rsvps.includes(user)}
              onClickRsvp={coming =>
                HangoutsApi.sendRsvp(coming, hangout, user)
              }
            />
          )}
          {/* Spacer, to pad last element */}
          <div style={{ width: 1, flex: '0 0 auto' }}/>
        </HorizontalScroller>
      </Section>
    )
  }

  renderRecentHangoutsSection() {
    const { recentHangouts } = this.state
    if (!recentHangouts.length) return null

    return (
      <Section>
        <SectionHeader
          title="Recent Hangouts"
          level={3}
          action={recentHangouts.length > 5 ? {
            title: 'See All',
            icon: FaList,
            onClick: () => []
          } : null}
        />
        <HorizontalScroller>
          {recentHangouts.map(hangout =>
            <HangoutCard
              key={hangout.id}
              hangout={hangout}
              onClick={() => this.props.history.push(`/hangouts/${hangout.id}`)}
            />
          )}
          {/* Spacer, to pad last element */}
          <div style={{ width: 1, flex: '0 0 auto' }}/>
        </HorizontalScroller>
      </Section>
    )
  }

  renderListsSection() {
    const { listsLoading, lists } = this.state
    return (
      <Section>
        <SectionHeader
          title="Lists"
          action={{
            title: 'Add',
            icon: FaCalendarPlus,
            onClick: () => this.props.history.push('/lists/new')
          }}
        />
        {listsLoading && <LoadingSpinner size="large" color="green"/>}
        {!listsLoading && !lists.length && (
          <span>
            You have no lists! <Link to="/lists/new">Create one</Link> now!
          </span>
        )}
        {!listsLoading && !!lists.length && (
          <Card style={{ width: '100%' }}>
            <ListsTable
              lists={this.state.lists}
              onListClicked={({ id }) => this.props.history.push(`/lists/${id}`)}
            />
          </Card>
        )}
      </Section>
    )
  }

  render() {
    const { user } = this.state

    return (
      <Layout>
        {this.renderSignInModal()}

        {user && (
          <>
            <Header user={user}/>
            <Main>
              {this.renderUpcomingHangoutsSection(user)}
              {this.renderRecentHangoutsSection()}
              {this.renderListsSection()}
            </Main>
          </>
        )}
      </Layout>
    )
  }
}

const HorizontalScroller = styled.div`
  flex: 1;
  display: flex;
  overflow-x: scroll;
  padding: 16px;
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  > *:not(:last-child) {
    margin-right: 24px;
  }
  
  /* Smartphones (portrait) ----------- */
  @media screen and (max-width: 800px) {
    // Make the scrolling feel edge-to-edge on a smartphone
    padding-left: 24px;
    margin-left: -24px;
    margin-right: -24px;
  }
`
