import moment from 'moment'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'

const dateFormat = 'YYYY-MM-DD'

export function groupHangouts(hangouts: HangoutDetails[], now = moment()) {
  type Chrono = 'upcoming' | 'current' | 'recent'

  const today = now.startOf('day')
  const groupings = groupBy(hangouts, (hangout) => {
    if (hangout.startDate.isAfter(today, 'day')) {
      return 'upcoming'
    }
    if (hangout.startDate.isBefore(today, 'day')) {
      return 'recent'
    }
    return 'current'
  }) as { [chrono in Chrono]: HangoutDetails[] }

  // Sort each chrono-type
  groupings.upcoming = sortBy(groupings.upcoming, ({ startDate }) => startDate.format(dateFormat))
  groupings.current = sortBy(groupings.current, ({ startDate }) => startDate.format(dateFormat))
  groupings.recent = sortBy(groupings.recent, ({ startDate }) => startDate.format(dateFormat)).reverse()

  return groupings
}
