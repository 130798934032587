import styled from 'styled-components'

interface Props {
  hoverable?: boolean
}

export const Card = styled.div<Props>`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  padding: 12px;
  width: 280px;
  border-radius: 6px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.25);
  background: white;
  transition: 300ms all ease-in-out;
  cursor: ${({ hoverable }) => !hoverable ? 'default' : 'pointer'};
  font-size: 14px;
  box-sizing: border-box;
  overflow: hidden;
  
  ${({ hoverable }) => hoverable && `&:hover {
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
  }`}
`
