import * as React from 'react'
import styled from 'styled-components'
import { Label } from './Label'

type HTMLInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

interface Props extends Omit<HTMLInputProps, 'size' | 'ref'> {
  label?: string,
  size?: 'small' | 'medium' | 'large',
  autofocus?: boolean,
  hasError?: boolean
}

export class Input extends React.PureComponent<Props> {
  ref = React.createRef<HTMLInputElement>()

  componentDidMount() {
    if (this.props.autofocus) {
      this.ref.current && this.ref.current.focus()
    }
  }

  render() {
    const { size, label, hasError = false, ...props } = this.props

    const fontSize = size === 'large' ? 36 : size === 'medium' ? 16 : 14
    const style = { ...props.style, fontSize }
    return (
      <div style={{ flex: 1 }}>
        {label && <Label>{label}</Label>}
        <StyledInput {...props} {...{ hasError, style }} ref={this.ref}/>
      </div>
    )
  }
}

interface InputProps {
  hasError: boolean
}

const StyledInput = styled.input<InputProps>`
  outline: none;
  font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
  padding: 10px;
  transition: 300ms all ease-in-out;
  border: 1px solid ${({ hasError }) => hasError ? '#9c3319' : 'lightgrey'};
  border-radius: 12px;
  color: #333;
  box-sizing: border-box;
  
  :active, :focus {
    border-color: ${({ hasError }) => hasError ? '#9c3319' : '#269E19'}
  }
`
