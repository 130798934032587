import * as React from 'react'
import { Checkbox } from '../../ui'
import { DraggableList, ListItemInput } from '../../components/DraggableList'

interface Props {
  activities: Activity[],
  onChange: (activities: Activity[]) => void,
  style?: React.CSSProperties
}

export class ActivitiesList extends React.PureComponent<Props> {
  addActivity = (text: string) => {
    const activities = this.props.activities.concat({ text, checked: false })
    this.props.onChange(activities)
  }

  deleteActivity = (index: number) => {
    const activities = [...this.props.activities]
    activities.splice(index, 1)
    this.props.onChange(activities)
  }

  modifyActivity = (index: number, text: string, checked: boolean) => {
    if (!text) return this.deleteActivity(index)

    const activities = [...this.props.activities]
    activities[index] = { text, checked }
    this.props.onChange(activities)
  }

  renderItem = ({ text, checked }: Activity, idx: number) => (
    <>
      <Checkbox
        checked={checked}
        onChange={checked => this.modifyActivity(idx, text, checked)}
      />
      <ListItemInput
        placeholder="Enter an activity"
        defaultValue={text}
        onBlur={e => this.modifyActivity(idx, e.currentTarget.value, checked)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            this.modifyActivity(idx, e.currentTarget.value, checked)
          }
        }}
      />
    </>
  )

  render() {
    const { activities, onChange, style } = this.props

    return (
      <DraggableList
        id="activities"
        style={style}
        items={activities}
        addItemPlaceholder="Enter a new activity!"
        getKey={(activity, idx) => `${activity.text}-${idx}`}
        onChange={onChange}
        onAddItem={this.addActivity}
        renderItem={this.renderItem}
      />
    )
  }
}
