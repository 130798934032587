import * as React from 'react'
import styled from 'styled-components'
import { FaCheckCircle, FaExclamationCircle, FaQuestionCircle } from 'react-icons/all'
import parseColor from 'color'
import noop from 'lodash/noop'
import { Card } from './Card'
import { ColorOption } from '../util/color'

interface Props {
  ref: React.Ref<NotificationCenter>
}

type NotificationType = 'danger' | 'info' | 'success'

interface Notif {
  title: string,
  type: NotificationType,
  message: JSX.Element | string,
  onClick: () => void
}

interface State {
  notification: Notif | null
}

interface NotificationOpts {
  timeout?: number | null,
  onClick?: () => void
}

export class NotificationCenter extends React.PureComponent<Props, State> {
  handle = 0

  state: State = { notification: null }

  componentWillUnmount() {
    this.handle && clearTimeout(this.handle)
  }

  pause() {
    this.handle && clearTimeout(this.handle)
  }

  resume() {
    this.handle = setTimeout(() => {
      this.setState({ notification: null })
    }, 4000)
  }

  success(title: string, message: JSX.Element | string, opts: NotificationOpts = {}) {
    const notification: Notif = {
      title,
      message,
      type: 'success',
      onClick: opts.onClick || noop
    }
    this.setState({ notification })

    const timeout = opts.timeout || 5000
    if (timeout) {
      this.handle = setTimeout(() => {
        this.setState({ notification: null })
      }, timeout)
    }
  }

  dismissCurrent() {
    this.handle && clearTimeout(this.handle)
    this.setState({ notification: null })
  }

  render() {
    return (
      <div>
        {this.state.notification && (
          <Notification
            {...this.state.notification}
            onMouseEnter={() => this.pause()}
            onMouseLeave={() => this.resume()}
          />
        )}
      </div>
    )
  }
}

interface NotificationProps {
  title: string,
  type: 'info' | 'danger' | 'success',
  message: JSX.Element | string,
  onClick: () => void,
  onMouseEnter: () => void
  onMouseLeave: () => void
}

function Notification({ title, type, message, onClick, onMouseEnter, onMouseLeave }: NotificationProps) {
  const icon = type === 'info'
    ? <FaQuestionCircle/>
    : type === 'success'
      ? <FaCheckCircle/>
      : <FaExclamationCircle/>
  const color: ColorOption = type === 'info'
    ? 'blue'
    : type === 'success'
      ? 'green'
      : 'red'

  return (
    <NotificationContainer {...{ onMouseLeave, onMouseEnter }}>
      <Card onClick={onClick}>
        <Title color={color}>{icon}&nbsp;{title}</Title>
        <span>{message}</span>
      </Card>
    </NotificationContainer>
  )
}

const NotificationContainer = styled.div`
  position: fixed;
  z-index: 10;
  right: 24px;
  top: 24px;
`

interface TitleProps {
  color: ColorOption
}

const Title = styled.span<TitleProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: ${({ color }) => parseColor(color).darken(0.2).string()};
  margin-bottom: 6px;
`
