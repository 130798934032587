import * as React from 'react'
import { BackButton } from './BackButton'

type Destination
  = { to: 'home' }
  | { to: 'create' }
  | { to: 'overview', hangoutId: string }

interface Props {
  destination: Destination,
}

export function Breadcrumb({ destination }: Props) {
  switch (destination.to) {
    case 'home':
      return <BackButton path="/" title="Home"/>
    case 'create':
      return <BackButton path="/hangouts/new" title="Create"/>
    case 'overview':
      return <BackButton path={`/hangouts/${destination.hangoutId}`} title="Hangout Details"/>
  }
}
