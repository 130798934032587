import moment from 'moment'

export function getDatesBetween(start: moment.Moment, end: moment.Moment) {
  const dates = []
  let date = start.clone().startOf('day').add(1, 'day')
  while (date.isBefore(end, 'day')) {
    dates.push(date.clone())
    date.add(1, 'day')
  }
  return dates
}