import * as React from 'react'
import styled from 'styled-components'
import { FaCaretDown } from 'react-icons/fa'
import { Input } from './Input'

interface Props {
  label?: string,
  value: string,
  placeholder: string,
  options: string[],
  onSelect: (option: string) => void,
  onClose?: () => void,
  size?: 'small' | 'medium' | 'large',
  autofocus?: boolean,
  style?: React.CSSProperties
}

export function Autosuggest({ label = '', value: inputValue, placeholder, options, onSelect, onClose, size, autofocus, style = {} }: Props) {
  const [value, setValue] = React.useState(inputValue)
  const [open, setOpen] = React.useState(false)
  const [opts, setOpts] = React.useState(options)
  const [selected, setSelected] = React.useState(options.indexOf(value))

  React.useEffect(() => {
    setValue(inputValue)
  }, [inputValue])

  return (
    <AutosuggestContainer style={style}>
      <AutosuggestInput
        {...{ label, placeholder, value, size, autofocus }}
        onChange={({ target: { value } }) => {
          setValue(value)
          if (!open) {
            setOpen(true)
          }
          setSelected(-1)
          const opts = options.filter(opt => opt.toLowerCase().includes(value.toLowerCase()))
          if (value && !options.includes(value)) {
            opts.push(`New Location: ${value}`)
          }
          setOpts(opts)
        }}
        onKeyDown={e => {
          switch (e.key) {
            case 'ArrowUp':
              setSelected((selected - 1 + opts.length) % opts.length)
              return
            case 'ArrowDown':
              if (!open) {
                setOpen(true)
              } else {
                setSelected((selected + 1) % opts.length)
              }
              return
            case 'Enter':
              let opt = opts[selected] || value
              if (opt.startsWith('New Location: ')) {
                opt = opt.replace('New Location: ', '')
              }
              setOpen(false)
              onSelect(opt)
              return
          }
        }}
        onFocus={() => setOpen(true)}
        onBlur={() => {
          setOpen(false)
          onClose && onClose()
        }}
      />
      <CaretContainer flipped={open}>
        <FaCaretDown/>
      </CaretContainer>
      {open && (
        <OptionsContainer>
          <ul>
            {opts.map((opt, idx) =>
              <Option
                key={opt}
                active={idx === selected}
                onMouseDown={() => {
                  let opt = opts[idx]
                  if (opt.startsWith('New Location: ')) {
                    opt = opt.replace('New Location: ', '')
                  }
                  setOpen(false)
                  setSelected(idx)
                  onSelect(opt)
                }}
              >
                {opt}
              </Option>
            )}
          </ul>
        </OptionsContainer>
      )}
    </AutosuggestContainer>
  )
}

const AutosuggestContainer = styled.div`
  position: relative;
`

const AutosuggestInput = styled(Input)`
  :active, :focus {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`

const OptionsContainer = styled.div`
  background: white;
  border: 1px solid lightgrey;
  border-top: none;
  border-radius: 0 0 12px 12px;
  position: absolute;
  left: 0;
  right: 0;
  box-shadow: 0 4px 7px -3px rgba(0,0,0,0.25);
  z-index: 1;
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

const Option = styled.li<{ active: boolean }>`
  padding: 6px 12px;
  background: ${({ active }) => active ? '#e7e7e7' : 'transparent'};
  cursor: pointer;
  transition: 300ms all ease-in-out;
 
  &:hover {
    background: #e7e7e7;
  }
  
  &:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`

const CaretContainer = styled.div<{ flipped: boolean }>`
  position: absolute;
  right: 12px;
  bottom: 12px;
  height: 16px;
  color: #333;
  transition: 300ms all ease-in-out;
  transform-origin: center;
  ${({ flipped }) => flipped ? 'transform: rotate(180deg)' : ''};
`