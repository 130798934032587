import * as React from 'react'
import moment from 'moment'
import styled, { createGlobalStyle } from 'styled-components'
import DayPicker from 'react-day-picker'
import { FaUndo } from 'react-icons/fa'
import onClickOutside from 'react-onclickoutside'
import { getDatesBetween } from '../util/date-utils'
import 'react-day-picker/lib/style.css'

interface Props {
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  onChange: (start: moment.Moment, end: moment.Moment) => void,
  label?: string,
  autofocus?: boolean,
  style?: React.CSSProperties,
}

type Mode = 'start' | 'end'

interface State {
  open: boolean,
  start: moment.Moment | null,
  end: moment.Moment | null,
  mode: Mode
}

export const DateRangeSelectorInput = onClickOutside(class DateRangeSelectorInput extends React.PureComponent<Props, State> {
  triggerRef = React.createRef<HTMLDivElement>()

  constructor(props: Props) {
    super(props)

    this.state = {
      open: false,
      start: props.startDate,
      end: props.endDate,
      mode: 'start'
    }
  }

  // Called by onClickOutside
  handleClickOutside = () => {
    const { open, start, end } = this.state
    if (!open) return null

    this.setState({ open: false })
    this.props.onChange(start!, end!)
  }

  componentDidMount() {
    if (this.props.autofocus) {
      this.triggerRef.current && this.triggerRef.current.focus()
    }
  }

  reset = () => {
    this.setState({
      start: this.props.startDate,
      end: this.props.endDate,
      mode: 'start'
    })
  }

  onDayClick = (day: Date) => {
    const { mode, start, end } = this.state
    if (mode === 'start') {
      const selectedDay = moment(day)
      const end = selectedDay.clone().add(2, 'days')
      this.setState({ mode: 'end', start: selectedDay, end })
    } else {
      const selectedDay = moment(day)
      if ((start && selectedDay.isSame(start, 'days')) || (start && !end)) {
        const end = selectedDay.clone().add(2, 'days')
        this.setState({ mode: 'start', start: selectedDay, end })
      } else if (start && selectedDay.isBefore(start, 'days')) {
        this.setState({ start: selectedDay, end: start })
      } else {
        this.setState({ mode: 'start', end: selectedDay })
      }
    }
  }

  renderDropdown(now = new Date()) {
    const { mode, open, start, end } = this.state
    if (!open) return null

    const selectedDay = mode === 'start'
      ? (start ? start.toDate() : now)
      : (end ? end.toDate() : now)

    return (
      <OptionsContainer>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 12 }}>
          <DayPicker
            initialMonth={selectedDay}
            selectedDays={selectedDay}
            modifiers={{
              today: now,
              start: start ? start.toDate() : undefined,
              end: end ? end.toDate() : undefined,
              between: !!start && !!end
                ? getDatesBetween(start, end).map(m => m.toDate())
                : []
            }}
            onDayClick={this.onDayClick}
            modifiersStyles={{
              today: {
                color: '#269E19',
                backgroundColor: 'white'
              },
              between: {
                backgroundColor: 'rgba(38, 158, 25, 0.4)',
                borderRadius: 0
              },
              start: {
                color: mode === 'start' ? 'white' : 'inherit',
                backgroundColor: mode === 'start' ? '#269E19' : 'rgba(38, 158, 25, 0.4)',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
              },
              end: {
                color: mode === 'end' ? 'white' : 'inherit',
                backgroundColor: mode === 'end' ? '#269E19' : 'rgba(38, 158, 25, 0.4)',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
              }
            }}
          />
          <a
            style={{ display: 'flex', alignItems: 'center', marginTop: 6 }}
            onClick={this.reset}
          >
            <FaUndo/>&nbsp;Reset
          </a>
        </div>
      </OptionsContainer>
    )
  }

  render() {
    const { mode, open, start, end } = this.state

    return <>
      <GlobalStyle/>
      <AutosuggestContainer style={this.props.style}>
        <Label>{this.props.label}</Label>
        <Trigger
          tabIndex={-1}
          ref={this.triggerRef}
          onClick={() => this.setState({ mode: 'start' })}
          onFocus={() => this.setState({ open: true })}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DateLabel
              mode="start"
              active={open && mode === 'start'}
              onClick={() => this.setState({ mode: 'start' })}
            >
              {start ? start.format('MMM D') : 'From'}
            </DateLabel>
            <div style={{ flex: 1, textAlign: 'center', lineHeight: '32px' }}>-</div>
            <DateLabel
              mode="end"
              active={open && mode === 'end'}
              onClick={() => this.setState({ mode: 'end' })}
            >
              {end ? end.format('MMM D') : 'To'}
            </DateLabel>
          </div>
        </Trigger>
        {this.renderDropdown()}
      </AutosuggestContainer>
    </>
  }
})

const GlobalStyle = createGlobalStyle`
  .DayPickerInput {
    flex: 1;
  }
  
  .DayPicker-wrapper * {
    outline: none;
  }
`

const AutosuggestContainer = styled.div`
  width: 100%;
  position: relative;
`

const DateLabel = styled.div<{ mode: Mode, active: boolean }>`
  flex: 1;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: ${({ active }) => active ? 'bold' : 'normal'};
  color: ${({ active }) => active ? 'white' : 'inherit'};
  background: ${({ active }) => active ? '#269E19' : 'none'};
  border-top-left-radius: ${({ active, mode }) => mode === 'start' && active ? 12 : 0}px;
  border-bottom-left-radius: ${({ active, mode }) => mode === 'start' && active ? 12 : 0}px;
  border-top-right-radius: ${({ active, mode }) => mode === 'end' && active ? 12 : 0}px;
  border-bottom-right-radius: ${({ active, mode }) => mode === 'end' && active ? 12 : 0}px;
  transition: background-color 300ms, ease-in-out, color 300ms ease-in-out;
`

const Trigger = styled.div`
  outline: none;
  font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
  padding: 3px;
  transition: 300ms all ease-in-out;
  border: 1px solid lightgrey;
  border-radius: 12px;
  color: #333;
  font-size: 14px;
  background-color: white;
  box-sizing: border-box;
  cursor: pointer;
  
  :active, :focus {
    border-color: #269E19;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`

const Label = styled.span`
  font-size: 13px;
  font-weight: bold;
`

const OptionsContainer = styled.div`
  background: white;
  border: 1px solid lightgrey;
  border-top: none;
  border-radius: 0 0 12px 12px;
  position: absolute;
  left: 0;
  right: 0;
  box-shadow: 0 4px 7px -3px rgba(0,0,0,0.25);
  z-index: 1;
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`
