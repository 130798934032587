import * as React from 'react'
import { Link } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/all'

interface Props {
  path?: string,
  onClick?: () => void,
  title?: string,
  style?: React.CSSProperties
}

export function BackButton({ path, onClick, title = 'Go back', style = {} }: Props) {
  if (path) {
    return (
      <Link to={path} style={{ flex: 1, display: 'inline-flex', alignItems: 'center', ...style }}>
        <FaChevronLeft/> {title}
      </Link>
    )
  }

  return (
    <a onClick={onClick} style={{ flex: 1, display: 'inline-flex', alignItems: 'center', ...style }}>
      <FaChevronLeft/> {title}
    </a>
  )
}
