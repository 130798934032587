import * as React from 'react'
import styled from 'styled-components'
import { Button, Input, Modal } from '../../ui'
import { getColor, getColorForUser } from '../../util/color'
import { login } from '../../api/auth'
import { BackButton } from '../../components/BackButton'

interface Props {
  open: boolean,
  onLogin: (user: UserType) => void
}

type ErrorType = 'password' | 'other'

interface State {
  selectedUser: UserType | null,
  showPassword: boolean,
  password: string,
  loading: boolean,
  error: ErrorType | null,
}

const userTypes: UserType[] = ['Meg', 'Ken', 'Brian', 'Kelsey']

export class SignInModal extends React.PureComponent<Props, State> {
  state: State = { selectedUser: null, showPassword: false, password: '', loading: false, error: null }

  renderButtonGrid() {
    return (
      <ButtonGrid>
        {userTypes.map(name =>
          <Button
            key={name}
            title={name}
            color={getColorForUser(name)}
            onClick={() => this.setState({ selectedUser: name, showPassword: true })}
          />
        )}
      </ButtonGrid>
    )
  }

  onSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const { selectedUser, password } = this.state
    if (!selectedUser) return

    await this.onLogin(selectedUser, password)
  }

  onLogin = async (selectedUser: UserType, password: string) => {
    try {
      this.setState({ loading: true, error: null })
      await login(selectedUser, password)
      this.setState({ loading: false })
      this.props.onLogin(selectedUser)
    } catch (e) {
      const error = (() => {
        if (e.code === 'auth/wrong-password') return 'password'

        console.log('Error logging in:', e)
        return 'other'
      })()
      this.setState({ loading: false, error })
    }
  }

  renderLoginForm(selectedUser: UserType) {
    return (
      <form onSubmit={this.onSubmit}>
        <Input
          label={`Log in as ${selectedUser}`}
          type="password"
          value={this.state.password}
          hasError={!!this.state.error}
          onChange={e => this.setState({ password: e.target.value })}
        />
        {this.state.error && (
          <Row>
            <ErrorSpan>
              {this.state.error === 'password'
                ? 'The password you entered was incorrect'
                : 'There was an error, please try again'
              }
            </ErrorSpan>
          </Row>
        )}
        <ButtonRow>
          <BackButton
            onClick={() => this.setState({ selectedUser: null, showPassword: false, password: '' })}
          />
          <Button
            style={{ flex: 1 }}
            disabled={!this.state.password}
            title="Login"
            loading={this.state.loading}
            onClick={() => this.onLogin(selectedUser, this.state.password)}
          />
        </ButtonRow>
      </form>
    )
  }

  render() {
    const { open } = this.props
    const { selectedUser, showPassword } = this.state

    const title = selectedUser ? 'Prove it!' : 'Who Are You?'

    return (
      <Modal {...{ open, title }} closable={false}>
        <Contents>
          {!showPassword && this.renderButtonGrid()}
          {showPassword && selectedUser && this.renderLoginForm(selectedUser)}
        </Contents>
      </Modal>
    )
  }
}

const Contents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`

const ButtonGrid = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  max-width: 360px;
`

const Row = styled.div`
  display: flex;
  margin-top: 12px;
`

const ButtonRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ErrorSpan = styled.span`
  font-size: 13px;
  color: ${getColor('red')};
`
