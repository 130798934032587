import * as React from 'react'
import styled from 'styled-components'
import orderBy from 'lodash/orderBy'
import noop from 'lodash/noop'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'

interface TableCellOpts<T> {
  row: T,
  rowIndex: number,
  colIndex: number,
  hoveredRow: number,
  hoveredCol: number
}

export interface TableColumn<T> {
  name: string,
  renderCell: (opts: TableCellOpts<T>) => JSX.Element | string | number,
  sortBy: (item: T) => string | number,
}

type SortDirection = 'asc' | 'desc'

interface Props<T> {
  columns: TableColumn<T>[],
  data: T[],
  keyFunction: (item: T) => string | number,
  defaultSortColumn?: string,
  defaultSortDirection?: SortDirection,
  tableRowProps?: {
    cursorPointer?: boolean,
    onClick?: (opts: TableCellOpts<T>) => void,
    style?: React.CSSProperties
  }
}

interface State<T> {
  sortColumn: TableColumn<T>,
  sortDirection: SortDirection,
  hovered: [number, number]
}

export class Table<T> extends React.PureComponent<Props<T>, State<T>> {
  constructor(props: Props<T>) {
    super(props)
    this.state = {
      sortColumn: props.columns.find(({ name }) => name === this.props.defaultSortColumn) || props.columns[0],
      sortDirection: this.props.defaultSortDirection || 'desc',
      hovered: [-1, -1]
    }
  }

  setHover = (rowIndex: number, colIndex: number) => this.setState({ hovered: [rowIndex, colIndex] })

  clearHover = () => this.setHover(-1, -1)

  getSortedRows = () => {
    return orderBy(this.props.data, this.state.sortColumn.sortBy, this.state.sortDirection)
  }

  onColHeaderClick = (col: TableColumn<T>) => {
    if (this.state.sortColumn.name === col.name) {
      const sortDirection = this.state.sortDirection === 'asc' ? 'desc' : 'asc'
      this.setState({ sortDirection })
    } else {
      this.setState({ sortColumn: col, sortDirection: 'asc' })
    }
  }

  renderCaret = (col: TableColumn<T>) => {
    if (this.state.sortColumn.name === col.name) {
      if (this.state.sortDirection === 'asc') {
        return <FaCaretUp/>
      }
      return <FaCaretDown/>
    }

    // Return hidden same-sized element to prevent janky-looking column width jumping
    return <div style={{ opacity: 0 }}><FaCaretUp/></div>
  }

  renderTableHeader = (col: TableColumn<T>, colIndex: number) => (
    <ColHeader key={col.name} onClick={() => this.onColHeaderClick(col)}>
      <ColHeaderContents>
        {colIndex === 0
          ? <>{col.name}&nbsp;{this.renderCaret(col)}</>
          : <>{this.renderCaret(col)}&nbsp;{col.name}</>
        }
      </ColHeaderContents>
    </ColHeader>
  )

  render() {
    if (this.props.data.length === 0) return null

    return (
      <StyledTable>
        <thead>
        <tr>
          {this.props.columns.map(this.renderTableHeader)}
        </tr>
        </thead>

        <tbody>
        {this.getSortedRows().map((row, rowIndex) => {
          const rowKey = this.props.keyFunction(row)
          const {
            cursorPointer = false,
            onClick = noop,
            style = {}
          } = this.props.tableRowProps || {}

          const [hoveredRow, hoveredCol] = this.state.hovered

          return (
            <TableRow key={rowKey} {...{ cursorPointer, style }}>
              {this.props.columns.map(({ name, renderCell }, colIndex) => {
                const key = `${rowKey}-${name}`

                return (
                  <Cell
                    key={key}
                    onClick={() => onClick({ row, rowIndex, colIndex, hoveredRow, hoveredCol })}
                    onMouseEnter={() => this.setHover(rowIndex, colIndex)}
                    onMouseLeave={this.clearHover}
                  >
                    {renderCell({ row, rowIndex, colIndex, hoveredRow, hoveredCol })}
                  </Cell>
                )
              })}
            </TableRow>
          )
        })}
        </tbody>
      </StyledTable>
    )
  }
}

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`

const ColHeaderContents = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const ColHeader = styled.th`
  padding: 12px;
  background-color: #f3f3f3;
  cursor: pointer;
  user-select: none;
  transition: all 300ms ease-in-out;
  border-right: 1px solid #ddd;
  
  &:first-child {
    border-top-left-radius: 6px;
    
    ${ColHeaderContents} {
      justify-content: flex-start;
    }
  }
  
  &:last-child {
    border-top-right-radius: 6px;
    border-right: none;
  }
  
  &:hover {
    background-color: #ddd;
  }
`

const Cell = styled.td`
  padding: 10px;
  
  &:not(:first-child) {
    text-align: end;
  }
`

const TableRow = styled.tr<{ cursorPointer: boolean }>`
  transition: all 100ms ease-in-out;
  cursor: ${({ cursorPointer }) => cursorPointer ? 'pointer' : 'default'};
  
  &:hover {
    background-color: #f7f7f7;
  }
  
  &:last-child {
    ${Cell} {
      &:first-child {
        border-bottom-left-radius: 6px;
      }
      
      &:last-child {
        border-bottom-right-radius: 6px;
      }
    }
  }
`
