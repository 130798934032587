import * as React from 'react'
import styled from 'styled-components'
import noop from 'lodash/noop'
import { FaTimes } from 'react-icons/fa'
import { Motion, spring } from 'react-motion'

interface Props {
  open: boolean,
  title: JSX.Element | string,
  children: JSX.Element | JSX.Element[],
  onClose?: () => void,
  closable?: boolean,
  footerButtons?: JSX.Element[],
}

type ModalAnimState = 'closed' | 'closing' | 'opening' | 'open'

export default function Modal(props: Props) {
  const [state, setState] = React.useState<ModalAnimState>('closed')

  React.useEffect(() => {
    if (props.open) {
      if (state === 'closed' || state === 'closing') {
        setState('opening')
      }
    } else {
      if (state === 'open' || state === 'opening') {
        setState('closing')
      }
    }
  }, [state, props.open])

  if (state === 'closed') return null

  const defaultStyle = state === 'opening' || state === 'open'
    ? { opacity: 0, translateY: 50 }
    : { opacity: 1, translateY: 0 }

  const style = state === 'opening' || state === 'open'
    ? { opacity: spring(1), translateY: spring(0) }
    : { opacity: spring(0), translateY: spring(50) }

  return (
    <Motion
      defaultStyle={defaultStyle}
      style={style}
      onRest={() => {
        if (state === 'opening') setState('open')
        if (state === 'closing') setState('closed')
      }}
    >
      {({ opacity, translateY }) =>
        <ModalInner {...{ opacity, translateY }} {...props} />
      }
    </Motion>
  )
}

interface ModalProps extends Props {
  opacity: number,
  translateY: number
}

function ModalInner({ title, onClose, closable, footerButtons, children, opacity, translateY }: ModalProps) {
  return (
    <BackgroundScreen style={{ opacity }} onClick={closable === false ? noop : onClose}>
      <ModalContainer style={{ opacity, transform: `translateY(${translateY}px)` }} onClick={e => e.stopPropagation()}>
        <ModalHeader>
          {typeof title === 'string' ? <h2>{title}</h2> : title}
          {closable !== false && (
            <XButtonContainer onClick={onClose}>
              <FaTimes/>
            </XButtonContainer>
          )}
        </ModalHeader>
        <ModalContent>
          {children}
        </ModalContent>
        {footerButtons && (
          <ModalFooter>
            {footerButtons.map((btn, idx) =>
              <div key={btn.key || idx} style={{ marginLeft: 12 }}>{btn}</div>
            )}
          </ModalFooter>
        )}
      </ModalContainer>
    </BackgroundScreen>
  )
}

const BackgroundScreen = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #f7faf7;
  min-width: 800px;
  max-width: 90%;
  max-height: 75%;
  border-radius: 12px;
  
  /* Smartphones (portrait) ----------- */
  @media screen and (max-width: 800px) {
    min-width: 100%;
    max-height: 100%;
  }
`

const ModalHeader = styled.header`
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 16px;
  border-bottom: 1px solid #654b472b;
`

const ModalContent = styled.section`
  flex-grow: 1;
  padding: 16px;
  overflow: scroll;
`

const ModalFooter = styled.footer`
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px;
  border-top: 1px solid #654b472b;
`

const XButtonContainer = styled.div`
  display: flex;
  opacity: 0.5;
  cursor: pointer;
  border-radius: 50%;
  padding: 8px;
  transition: 300ms all ease-in-out;
  
  &:hover {
    opacity: 1;
    background-color: #654b472b;
  }
`

