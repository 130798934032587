import * as React from 'react'
import { Button } from './Button'
import styled from 'styled-components'

interface Props {
  options: string[],
  renderOption: (option: string) => JSX.Element,
  selectedOption: string | null,
  onSelect: (option: string) => void
}

export function RadioButtonSet({ options, renderOption, selectedOption, onSelect }: Props) {
  return (
    <ButtonSetGrid>
      {options.map(opt =>
        opt === selectedOption
          ? <Button key={opt} title={opt} type="ghost" noHover/>
          : (
            <div key={opt} onClick={() => onSelect(opt)} style={{ padding: 12, cursor: 'pointer' }}>
              {renderOption(opt)}
            </div>
          )
      )}
    </ButtonSetGrid>
  )
}

const ButtonSetGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  grid-gap: 48px;
  padding: 12px;
`
