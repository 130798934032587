interface SearchResponse {
  total: number,
  totalPages: number,
  results: UnsplashPhoto[]
}

const API_KEY = process.env.REACT_APP_UNSPLASH_API_KEY
const BASE_URL = `https://api.unsplash.com/search/photos?client_id=${API_KEY}`

export async function searchPhotos(query: string, page = 1): Promise<SearchResponse> {
  const url = `${BASE_URL}&page=${page}&query=${query}`
  const res = await fetch(url)
  const { total, total_pages, results } = await res.json()

  return {
    total,
    totalPages: total_pages,
    results
  }
}
