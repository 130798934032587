import * as React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Section, Autosuggest, TextArea, DateRangeSelectorInput, Label } from '../../ui'
import { ActivitiesList } from '../HangoutDetailsPage/ActivitiesList'

interface Fields {
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  location: string,
  activities: Activity[],
  notes: string,
}

interface Props extends Fields {
  onChange: (fields: Fields) => void,
}

export const locations = ['Ken & Meg', 'Kelsey', 'Brian']

export function HangoutDetailsForm({ onChange, ...fields }: Props) {
  return <>
    <Section>
      <h3>Planning</h3>
      <Row>
        <RowItem>
          <DateRangeSelectorInput
            label="Dates"
            startDate={fields.startDate}
            endDate={fields.endDate}
            onChange={(startDate, endDate) => onChange({ ...fields, startDate, endDate })}
          />
        </RowItem>
        <RowItem>
          <Autosuggest
            style={{ flex: 1 }}
            label="Location"
            size="small"
            placeholder="Select a location..."
            value={fields.location}
            options={locations}
            onSelect={location => onChange({ ...fields, location })}
          />
        </RowItem>
      </Row>
    </Section>
    <Section>
      <h3>Details</h3>
      <Row style={{ marginBottom: 12 }}>
        <RowItem style={{ flexDirection: 'column' }}>
          <Label style={{ marginBottom: 6 }}>Activities</Label>
          <ActivitiesList
            style={{ flex: 1 }}
            activities={fields.activities}
            onChange={activities => onChange({ ...fields, activities })}
          />
        </RowItem>
      </Row>
      <Row>
        <RowItem>
          <TextArea
            style={{ flex: 1 }}
            rows={4}
            label="Notes"
            size="small"
            placeholder={`e.g. "Remember to bring Terraforming Mars!"`}
            value={fields.notes}
            onChange={e => onChange({ ...fields, notes: e.target.value })}
          />
        </RowItem>
      </Row>
    </Section>

  </>
}

const Row = styled.div`
  display: flex;
  
  > * {
    margin-right: 24px;
    
    &:last-child {
      margin-right: 0;
    }
  }
  
  /* Smartphones (portrait) ----------- */
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-top: -12px;
    
    > * {
      margin-right: 0;
      margin-top: 12px;
    }
  }
`

const RowItem = styled.div`
  flex: 1;
  display: flex;
`
