import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { HomePage } from './pages/HomePage'
import { CreatePage } from './pages/CreatePage'
import { OverviewPage } from './pages/HangoutDetailsPage'
import { ListDetailsPage } from './pages/ListDetailsPage'

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/hangouts/new" component={CreatePage}/>
        <Route path="/hangouts/:hangoutId" component={OverviewPage}/>
        <Route path="/lists/new" component={ListDetailsPage}/>
        <Route path="/lists/:listId" component={ListDetailsPage}/>
        <Route path="/" exact component={HomePage}/>
      </Switch>
    </Router>
  )
}
