import * as React from 'react'
import styled from 'styled-components'
import { FaImage } from 'react-icons/fa'
import moment from 'moment'
import noop from 'lodash/noop'
import { Card } from '../ui'
import { Rsvps } from './RSVPs'

interface UpcomingHangoutCardProps {
  currentUser: UserType,
  hangout: HangoutDetails,
  showRsvp: boolean,
  onClickRsvp: (coming: boolean) => void,
  onClick?: () => void,
}

export function UpcomingHangoutCard({ currentUser, onClick, hangout, showRsvp, onClickRsvp }: UpcomingHangoutCardProps) {
  const { startDate, endDate, location, rsvps } = hangout

  const start = moment(startDate, 'YYYY-MM-DD')
  const end = moment(endDate, 'YYYY-MM-DD')
  const duration = moment.duration(start.diff(moment().startOf('day')))
  let durationMsg: string
  if (duration.asDays() === 1) {
    durationMsg = 'tomorrow'
  } else if (duration.asDays() < 1) {
    durationMsg = 'today!'
  } else {
    durationMsg = duration.humanize(true)
  }

  function renderRsvpButton() {
    if (!showRsvp) return null
    return (
      <a onClick={e => {
        e.stopPropagation()
        onClickRsvp(true)
      }}>
        I'm Coming!
      </a>
    )
  }

  return (
    <Card hoverable onClick={onClick || noop}>
      {renderCoverPhoto(hangout.imageUrl, hangout.title)}
      <InfoContainer>
        <TopLine>
        <span className="dates">
          <b>When</b>: {start.format('MM/D')} - {end.format('MM/D')}
        </span>
          <span className="duration">({durationMsg})</span>
        </TopLine>
        <section style={{ marginTop: 12 }}>
        <span>
          <b>Where at</b>: {location}
        </span>
        </section>
        <section style={{ marginTop: 12 }}>
          <span><b>Who</b>:</span>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', height: 33 }}>
            {rsvps.length
              ? <Rsvps {...{ rsvps, currentUser, onClickRsvp }} />
              : <span>No RSVPs yet!</span>
            }
            {renderRsvpButton()}
          </div>
        </section>
      </InfoContainer>
    </Card>
  )
}

interface HangoutCardProps {
  hangout: HangoutDetails,
  onClick?: () => void,
}

export function HangoutCard({ onClick, hangout }: HangoutCardProps) {
  const { startDate, endDate, location } = hangout
  const start = moment(startDate, 'YYYY-MM-DD')
  const end = moment(endDate, 'YYYY-MM-DD')

  return (
    <Card hoverable onClick={onClick}>
      {renderCoverPhoto(hangout.imageUrl, hangout.title)}
      <InfoContainer>
        <TopLine>
        <span className="dates">
          <b>When</b>: {start.format('MM/D')} - {end.format('MM/D')}
        </span>
        </TopLine>
        <section style={{ marginTop: 12 }}>
        <span>
          <b>Where at</b>: {location}
        </span>
        </section>
      </InfoContainer>
    </Card>
  )
}

const InfoContainer = styled.div`
  background: white;
  margin: -12px;
  padding: 12px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  z-index: 1;
`

const TopLine = styled.div`
  display: flex;
  justify-content: space-between;
  
  .duration {
    text-align: right;
    font-size: 13px;
    font-style: italic;
  }
`

function renderCoverPhoto(imageUrl?: string, title?: string) {
  return (
    <CoverPhotoContainer>
      {imageUrl
        ? <img src={imageUrl}/>
        : (
          <CoverPhotoZeroState>
            <FaImage/>
            <div>No Cover Photo</div>
          </CoverPhotoZeroState>
        )
      }
      <CoverPhotoGradient/>
      {title && <Title>{title}</Title>}
    </CoverPhotoContainer>
  )
}

const IMAGE_WIDTH = 280
const IMAGE_HEIGHT = 157

const CoverPhotoContainer = styled.div`
  margin: -12px -12px 12px; // Negative top, left, and right margins to offset Card padding
  position: relative;
  width: ${IMAGE_WIDTH}px;
  height: ${IMAGE_HEIGHT}px;
  
  img {
    width: ${IMAGE_WIDTH}px;
    height: ${IMAGE_HEIGHT + 12}px;
    object-fit: cover;
  }
`

const CoverPhotoZeroState = styled.div`
  width: ${IMAGE_WIDTH}px;
  height: ${IMAGE_HEIGHT + 12}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #eaeaea;
  color: #888;
`

const CoverPhotoGradient = styled.div`
  position: absolute;
  top: 0;
  width: ${IMAGE_WIDTH}px;
  height: ${IMAGE_HEIGHT + 12}px; // Add 12px to force the gradient below the curved edges of the info container
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0,0,0,0.5) 100%);
`

const Title = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  padding: 8px 12px;
  font-size: 20px;
  font-weight: 600;
  text-shadow: 1px 1px black;
`
