export function isLoggedIn() {
  const id = localStorage.getItem('gorillas_id')
  return !!id
}

export function getUser(): UserType | null {
  const id = localStorage.getItem('gorillas_id')
  if (!id) return null
  return atob(id) as UserType
}

export function storeUser(user: UserType) {
  localStorage.setItem('gorillas_id', btoa(user))
}
