import * as React from 'react'
import styled from 'styled-components'
import noop from 'lodash/noop'
import onClickOutside from 'react-onclickoutside'

interface MenuProps {
  trigger: JSX.Element,
  options: Array<{
    title: JSX.Element | string,
    icon: JSX.Element,
    onClick?: () => void
  }>
}

interface MenuState {
  open: boolean,
  offset: { left?: number, right?: number },
}

const MENU_WIDTH = 200

export class Menu extends React.PureComponent<MenuProps, MenuState> {
  triggerRef = React.createRef<HTMLDivElement>()

  state: MenuState = { open: false, offset: { left: 0 } }

  componentDidMount() {
    this.onWindowResize({ currentTarget: window } as unknown as React.UIEvent<Window>)
    // @ts-ignore (The typing doesn't seem to work for window event listeners)
    window.addEventListener('resize', this.onWindowResize)
  }

  componentWillUnmount() {
    // @ts-ignore (The typing doesn't seem to work for window event listeners)
    window.removeEventListener('resize', this.onWindowResize)
  }

  onWindowResize = (e: React.UIEvent<Window>) => {
    const windowWidth = e.currentTarget.innerWidth
    const { left } = this.triggerRef.current!.getBoundingClientRect()

    const offset = left + MENU_WIDTH > windowWidth
      ? { right: 0 }
      : { left: 0 }
    this.setState({ offset })
  }

  // Called by onClickOutside
  handleClickOutside = () => {
    if (this.state.open) this.setState({ open: false })
  }

  toggleShowOptions = () => this.setState({ open: !this.state.open })

  onOptClick = (cb = noop) => {
    this.toggleShowOptions()
    cb()
  }

  render() {
    const { trigger, options } = this.props

    return (
      <div>
        <div style={{ position: 'relative' }}>
          <TriggerContainer ref={this.triggerRef} onClick={this.toggleShowOptions}>
            {trigger}
          </TriggerContainer>
          {this.state.open && (
            <div style={{ position: 'absolute', ...this.state.offset }}>
              <OptionsContainer>
                <ul>
                  {options.map((opt, idx) =>
                    <Option key={`${opt}-${idx}`} onClick={() => this.onOptClick(opt.onClick)}>
                      {opt.icon}&nbsp;{opt.title}
                    </Option>
                  )}
                </ul>
              </OptionsContainer>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default onClickOutside(Menu)

const TriggerContainer = styled.div`
  display: flex;
  opacity: 0.5;
  cursor: pointer;
  border-radius: 50%;
  padding: 8px;
  transition: 300ms all ease-in-out;
  
  &:hover {
    opacity: 1;
    background-color: #654b472b;
  }
`

const OptionsContainer = styled.div`
  width: ${MENU_WIDTH}px;
  background: white;
  border: 1px solid lightgrey;
  border-radius: 12px;
  box-shadow: 0 4px 7px -3px rgba(0,0,0,0.25);
  z-index: 1;
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

const Option = styled.li`
  display: flex;
  align-items: center;
  opacity: 0.75;
  cursor: pointer;
  padding: 12px;
  background-color: transparent;
  transition: 300ms all ease-in-out;
  
  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  
  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  
  &:hover {
    opacity: 0.8;
    background-color: #ececec;
  }
`
