import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import some from 'lodash/some'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import * as HangoutsApi from '../../api/hangouts'
import { Header, Layout, Main, Section, Button, NotificationCenter, Label } from '../../ui'
import { getUser } from '../../util/auth'
import { HangoutDetailsForm } from './HangoutDetailsForm'
import { Rsvps } from '../../components/RSVPs'
import { SectionHeader } from '../../components/SectionHeader'
import { Breadcrumb } from '../../components/Breadcrumb'
import { EditableTextField } from '../../components/Editables'
import { CoverPhotoPreview } from '../../components/CoverPhotoPreview'

type Props = RouteComponentProps

interface State {
  mode: 'create' | 'edit'
  saving: boolean,
  originalHangout: HangoutDetails | null,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  location: string,
  activities: Activity[],
  notes: string,
  title: string,
  imageUrl: string,
}

const initialState = {
  startDate: null,
  endDate: null,
  location: '',
  activities: [],
  notes: '',
  title: '',
  imageUrl: ''
}

export default class CreatePage extends React.PureComponent<Props, State> {
  notificationCenter = React.createRef<NotificationCenter>()

  constructor(props: Props) {
    super(props)
    this.state = {
      mode: 'create',
      saving: false,
      originalHangout: null,
      ...initialState
    }
  }

  isCreateable() {
    const { mode, startDate, endDate, location } = this.state
    if (mode !== 'create') return false
    return !!startDate && !!endDate && !!location
  }

  isDirty() {
    const { mode, originalHangout, startDate, endDate, location, activities, notes } = this.state
    if (mode !== 'edit' || !originalHangout) return false

    return some([
      !originalHangout.startDate.isSame(startDate, 'day'),
      !originalHangout.endDate.isSame(endDate, 'day'),
      originalHangout.location !== location,
      isEqual(originalHangout.activities, activities),
      originalHangout.notes !== notes
    ])
  }

  saveHangout = async () => {
    this.setState({ saving: true })

    const hangout: HangoutDetails = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      location: this.state.location,
      activities: this.state.activities,
      notes: this.state.notes,
      rsvps: this.state.originalHangout ? this.state.originalHangout.rsvps : [],
      title: this.state.title,
      imageUrl: this.state.imageUrl
    }

    let successMessage
    if (this.state.mode === 'edit') {
      hangout.id = this.state.originalHangout!.id // In edit mode, this value is known to be set
      await HangoutsApi.update(hangout)

      this.setState({ saving: false, originalHangout: hangout })
      successMessage = 'Hangout changes have been saved.'
    } else {
      hangout.id = await HangoutsApi.create(hangout)

      this.setState({ saving: false, mode: 'edit', originalHangout: hangout })
      successMessage = 'Hangout has been created.'
    }

    this.notificationCenter.current!.success(
      'Success!',
      <div>{successMessage}<br/>Click here to return to the Home screen.</div>,
      { onClick: () => this.props.history.push('/') }
    )
  }

  sendRsvp = (coming: boolean, hangout: HangoutDetails, user: UserType) =>
    HangoutsApi.sendRsvp(coming, hangout, user)

  renderRsvpsSection() {
    const user = getUser()
    if (!user) return
    const { originalHangout: hangout } = this.state
    if (!hangout) return

    const rsvpButton = (hangout.rsvps && !hangout.rsvps.includes(user))
      ? { title: 'Send RSVP', onClick: () => this.sendRsvp(true, hangout, user) }
      : null

    return (
      <Section>
        <SectionHeader title="RSVPs" level={3} action={rsvpButton}/>
        {hangout.rsvps && hangout.rsvps.length
          ? (
            <Rsvps
              currentUser={user}
              rsvps={hangout.rsvps || []}
              onClickRsvp={coming => this.sendRsvp(coming, hangout, user)}
            />
          ) : <span>No RSVPs yet!</span>
        }
      </Section>
    )
  }

  render() {
    const user = getUser()
    if (!user) return <Redirect to="/"/>

    const { mode, startDate, endDate, location, activities, notes, title, imageUrl } = this.state

    return (
      <Layout>
        <Header user={user}/>
        <NotificationCenter ref={this.notificationCenter}/>

        <Main>
          <Breadcrumb destination={{ to: 'home' }}/>
          <h1>Create Hangout</h1>

          <Section>
            <Label style={{ marginBottom: 6 }}>Title</Label>
            <EditableTextField
              placeholder={'Enter a title, e.g. "Friendsgiving"'}
              value={title}
              tagName="div"
              onSave={title => this.setState({ title })}
            />
          </Section>

          {mode === 'edit' && this.renderRsvpsSection()}
          <HangoutDetailsForm
            onChange={hangout => this.setState(hangout)}
            {...{ startDate, endDate, location, activities, notes }}
          />

          <Section>
            <SectionHeader title="Misc" level={3}/>
            <Label style={{ marginBottom: 6 }}>Cover Photo</Label>
            <CoverPhotoPreview
              imageUrl={imageUrl}
              onSelectImageUrl={imageUrl => this.setState({ imageUrl })}
            />
          </Section>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              title={mode === 'edit' ? 'Save' : 'Create'}
              size="large"
              disabled={mode === 'edit' ? !this.isDirty() : !this.isCreateable()}
              loading={this.state.saving}
              onClick={this.saveHangout}
            />
          </div>
        </Main>
      </Layout>
    )
  }
}
