import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { FaTrash } from 'react-icons/fa'
import noop from 'lodash/noop'
import moment from 'moment'
import {
  Header,
  Layout,
  Main,
  Section,
  MessageBanner,
  LoadingSpinner,
  NotificationCenter,
  UnderlinedLabel, Label, Button
} from '../../ui'
import { getUser } from '../../util/auth'
import * as HangoutsApi from '../../api/hangouts'
import { Rsvps } from '../../components/RSVPs'
import { SectionHeader } from '../../components/SectionHeader'
import { Breadcrumb } from '../../components/Breadcrumb'
import {
  EditableAutoSuggestField,
  EditableDateRange,
  EditableTextArea,
  EditableTextField
} from '../../components/Editables'
import { ActivitiesList } from './ActivitiesList'
import { CoverPhotoPreview } from '../../components/CoverPhotoPreview'
import { ArchiveHangoutConfirmationModal } from '../../components/ArchiveHangoutConfirmationModal'

type Props = RouteComponentProps<{ hangoutId: string }>

interface State {
  user: UserType | null,
  loading: boolean,
  error: 'not-found' | null,
  hangout: HangoutDetails | null,
  showArchiveConfirmationModal: boolean
}

export default class OverviewPage extends React.PureComponent<Props, State> {
  unsubscribe = noop
  notificationCenter = React.createRef<NotificationCenter>()

  constructor(props: Props) {
    super(props)

    const user = getUser()
    this.state = { user, loading: true, error: null, hangout: null, showArchiveConfirmationModal: false }
  }

  componentDidMount() {
    const { hangoutId } = this.props.match.params
    this.unsubscribe = HangoutsApi.subscribeOne(hangoutId, hangout => {
      if (!hangout) {
        return this.setState({ loading: false, error: 'not-found' })
      }

      this.setState({ loading: false, hangout })
    })
  }

  sendRsvp = (coming: boolean, hangout: HangoutDetails, user: UserType) =>
    HangoutsApi.sendRsvp(coming, hangout, user)

  saveChanges = async (
    hangout: HangoutDetails,
    changeType: 'dates' | 'location' | 'activities' | 'notes' | 'title' | 'image',
    isUndo = false
  ) => {
    const originalHangout = this.state.hangout!
    // Set it in state before updating in firebase; show feedback regardless of network latency
    this.setState({ hangout })

    if (isUndo) {
      this.notificationCenter.current!.dismissCurrent()

      await HangoutsApi.update(hangout)
      this.notificationCenter.current!.success(
        'Changes reverted',
        <div>Everything should be back as it was before</div>
      )
      return
    }

    switch (changeType) {
      case 'dates':
        if (
          originalHangout.startDate.isSame(hangout.startDate, 'day') ||
          originalHangout.endDate.isSame(hangout.endDate, 'day')
        ) {
          return
        }
        break
      case 'location':
      case 'notes':
      case 'title':
        if (originalHangout[changeType] === hangout[changeType]) return
        break
      case 'image':
        if (originalHangout.imageUrl === hangout.imageUrl) return
        break
      case 'activities':
        // If we've changed activities, update and don't show any notifications; chances are
        // if they're adding/modifying activities, they're doing it a lot and we don't need to
        // show a notification every time
        await HangoutsApi.update(hangout)
        return
    }

    await HangoutsApi.update(hangout)
    this.notificationCenter.current!.success(
      'Changes saved',
      <div>
        Updated {changeType} for hangout<br/>
        <a onClick={() => this.saveChanges(originalHangout, changeType, true)}>
          Click here
        </a>&nbsp;to undo changes
      </div>
    )
  }

  renderTitle() {
    const { hangout } = this.state
    if (!hangout) return <h1>&nbsp;</h1>

    return (
      <div style={{
        display: 'flex',
        flexWrap: 'wrap-reverse',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '22px 0'
      }}>
        <EditableDateRange
          startDate={hangout.startDate}
          endDate={hangout.endDate}
          onSave={(startDate, endDate) =>
            this.saveChanges({ ...hangout, startDate, endDate }, 'dates')
          }
        />
        <span style={{ fontSize: 13, fontStyle: 'italic', margin: '11px 0' }}>
          Click a field to change it. Changes will be saved automatically
        </span>
      </div>
    )
  }

  renderOverview(today = moment()) {
    const { hangout, user } = this.state
    if (!hangout || !user) return
    const { rsvps, activities } = hangout

    let rsvpAction = null
    if (!hangout.rsvps.includes(user)) {
      let title
      if (hangout.endDate.isBefore(today, 'day')) {
        title = 'I was there!'
      } else if (hangout.startDate.isAfter(today, 'day')) {
        title = 'I can come!'
      } else {
        title = `I'm here!`
      }
      rsvpAction = {
        title,
        onClick: () => this.sendRsvp(true, hangout, user)
      }
    }

    return (
      <div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Section>
            <Label style={{ marginBottom: 6 }}>Title</Label>
            <EditableTextField
              placeholder={'Enter a title, e.g. "Friendsgiving"'}
              value={hangout.title || ''}
              tagName="div"
              onSave={title => this.saveChanges({ ...hangout, title }, 'title')}
            />
          </Section>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Section style={{ marginRight: 12, minWidth: 300 }}>
            <h3>Where</h3>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              At&nbsp;
              <EditableAutoSuggestField
                placeholder="(Unselected)"
                value={hangout.location}
                onSave={location =>
                  this.saveChanges({ ...hangout, location }, 'location')
                }
              />
            </div>
          </Section>
          <Section style={{ minWidth: 300 }}>
            <SectionHeader title="Who" level={3} action={rsvpAction}/>
            {hangout.rsvps.length
              ? (
                <Rsvps
                  rsvps={rsvps}
                  currentUser={user}
                  onClickRsvp={coming => this.sendRsvp(coming, hangout, user)}
                />
              ) : <span>Nobody (yet)</span>
            }
          </Section>
        </div>
        <Section>
          <SectionHeader title="What" level={3}/>
          <div style={{ flex: 1 }}>
            <UnderlinedLabel>Activities</UnderlinedLabel>
            <ActivitiesList
              activities={activities}
              onChange={activities => this.saveChanges(({ ...hangout, activities }), 'activities')}
            />
          </div>
        </Section>
        <Section>
          <div style={{ flex: 1 }}>
            <UnderlinedLabel>Notes</UnderlinedLabel>
            <EditableTextArea
              placeholder={`e.g. "Remember to bring Terraforming Mars!"`}
              value={hangout.notes}
              onSave={notes =>
                this.saveChanges({ ...hangout, notes }, 'notes')
              }
            />
          </div>
        </Section>
        <Section>
          <SectionHeader title="Misc" level={3}/>
          <Label style={{ marginBottom: 6 }}>Cover Photo</Label>
          <CoverPhotoPreview
            imageUrl={hangout.imageUrl}
            onSelectImageUrl={imageUrl => this.saveChanges({ ...hangout, imageUrl }, 'image')}
          />
        </Section>
      </div>
    )
  }

  render() {
    const { user, hangout, loading, error, showArchiveConfirmationModal } = this.state
    if (!user) return <Redirect to="/"/>

    return (
      <Layout>
        <Header user={user}/>
        <NotificationCenter ref={this.notificationCenter}/>
        {hangout && <ArchiveHangoutConfirmationModal
          open={showArchiveConfirmationModal}
          onClose={() => this.setState({ showArchiveConfirmationModal: false })}
          hangout={hangout}
          onArchive={() => this.props.history.push('/')}
        />}

        <Main>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div>
              <Breadcrumb destination={{ to: 'home' }}/>
            </div>
            <Button
              type="ghost"
              color="lightgray"
              title={<><FaTrash/>&nbsp;Archive</>}
              onClick={() => {
                if (!hangout) return;
                this.setState({ showArchiveConfirmationModal: true })
              }}
            />
          </div>
          {this.renderTitle()}

          {loading && <LoadingSpinner size="large" color="green"/>}
          {!loading && error === 'not-found' && (
            <MessageBanner title="Error" type="danger">
              <p>
                Could not find Hangout. Please try going back and selecting a different one.
              </p>
              <Breadcrumb destination={{ to: 'home' }}/>
            </MessageBanner>
          )}
          {!loading && !error && this.renderOverview()}
        </Main>
      </Layout>
    )
  }
}

