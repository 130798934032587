import * as React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import { Table } from '../../ui'
import { TableColumn } from '../../ui/Table'

interface Props {
  lists: List[],
  onListClicked: (list: List) => void
}

const columns: TableColumn<List>[] = [
  {
    name: 'Name',
    sortBy: item => item.name,
    renderCell: ({ row, rowIndex, hoveredRow }) => hoveredRow === rowIndex
      ? <Link to={`lists/${row.id}`}>{row.name}</Link>
      : row.name
  },
  {
    name: 'Created By',
    sortBy: item => item.createdBy,
    renderCell: ({ row }) => row.createdBy
  },
  {
    name: 'Number of Items',
    sortBy: item => item.items.length,
    renderCell: ({ row }) => row.items.length
  },
  {
    name: 'Last Modified (By)',
    sortBy: item => item.lastModified.valueOf(),
    renderCell: ({ row }) => {
      const timeAgo = moment.duration(row.lastModified.diff(moment())).humanize(true)
      return <>
        {timeAgo}&nbsp;<i>({row.lastModifiedBy})</i>
      </>
    }
  }
]

const columnsMobile: TableColumn<List>[] = [
  {
    name: 'Name',
    sortBy: item => item.name,
    renderCell: ({ row, rowIndex, hoveredRow }) => (
      <div>
        {hoveredRow === rowIndex
          ? <Link to={`lists/${row.id}`}>{row.name}</Link>
          : <span>{row.name}</span>
        }
        <div>({row.items.length} items)</div>
      </div>
    )
  },
  {
    name: 'Last Modified (By)',
    sortBy: item => item.lastModified.valueOf(),
    renderCell: ({ row }) => {
      const timeAgo = moment.duration(row.lastModified.diff(moment())).humanize(true)
      return (
        <div>
          <span>{timeAgo}</span>
          <div><i>({row.lastModifiedBy})</i></div>
        </div>
      )
    }
  }
]

export function ListsTable({ lists, onListClicked }: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  const cols = isMobile ? columnsMobile : columns

  return (
    <Table
      tableRowProps={{
        cursorPointer: true,
        onClick: ({ row }) => onListClicked(row)
      }}
      defaultSortColumn="Last Modified (By)"
      defaultSortDirection="desc"
      columns={cols}
      data={lists}
      keyFunction={item => item.id!}
    />
  )
}