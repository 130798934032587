import { FaChevronLeft } from 'react-icons/all'
import * as React from 'react'

interface Props {
  text: string,
  onClick: () => void,
  disabled?: boolean,
  leading?: JSX.Element,
  trailing?: JSX.Element,
  style?: React.CSSProperties
}

export function TextButton({ text, onClick, disabled, leading, trailing, style = {} }: Props) {
  if (disabled) {
    style.color = 'darkgray'
  }

  return (
    <a
      onClick={onClick}
      style={{
        flex: 1,
        display: 'inline-flex',
        alignItems: 'center',
        ...style
      }}
    >
      {leading}&nbsp;{text}&nbsp;{trailing}
    </a>
  )
}